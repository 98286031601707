import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { Tooltip } from 'components/ui/tooltip';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';

type PaginationButtonsProps = {
  size: number;
  currentPage: number;
  totalPages: number;
  onPageClick: (page: number, size: number) => void;
  onSizeChange: (page: number, size: number) => void;
  styles?: FlexProps;
  options?: {
    value: number;
    label: string;
  }[];
};

export const PaginationButtons = ({
  currentPage,
  totalPages,
  onPageClick,
  onSizeChange,
  size,
  styles = {},
  options = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
}: PaginationButtonsProps) => {
  const pages = [];
  const maxPageButtonsToShow = 1;

  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 ||
      i === totalPages ||
      i === currentPage ||
      (i >= currentPage - maxPageButtonsToShow && i <= currentPage + maxPageButtonsToShow)
    ) {
      pages.push(
        <Tooltip content={String(i)} key={i}>
          <Button
            border={'1px solid rgba(207, 208, 216, 0.60)'}
            key={i}
            textAlign={'center'}
            onClick={() => onPageClick(i, size)}
            padding={'2px 2px'}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="40px"
            style={{
              backgroundColor: currentPage === i ? '#4285F4' : '#FFFFFF',
            }}
          >
            <Text lineClamp={1} style={{ color: currentPage === i ? '#FFFFFF' : '#383D58' }}>
              {String(i)}
            </Text>
          </Button>
        </Tooltip>
      );
    } else if (i === currentPage - maxPageButtonsToShow - 1 || i === currentPage + maxPageButtonsToShow + 1) {
      pages.push(
        <Button
          key={i}
          disabled={true}
          style={{
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: 'white',
            color: 'black',
            cursor: 'not-allowed',
          }}
        >
          ...
        </Button>
      );
    }
  }

  return (
    <Flex maxW={'full'} flexDirection={'row'} gap="1" alignSelf={'center'} alignItems={'center'} {...styles}>
      <Button
        colorPalette="gray"
        variant="outline"
        border={'1px solid rgba(207, 208, 216, 0.60)'}
        onClick={() => onPageClick(currentPage - 1, size)}
        disabled={currentPage === 1}
        style={{
          cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
        }}
      >
        <MdOutlineArrowBackIos />
      </Button>
      {pages}
      <Button
        colorPalette="gray"
        variant="outline"
        border={'1px solid rgba(207, 208, 216, 0.60)'}
        onClick={() => onPageClick(currentPage + 1, size)}
        disabled={currentPage === totalPages}
        style={{
          cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
        }}
      >
        <MdOutlineArrowForwardIos />
      </Button>
      <Text fontSize="sm" mx={'8px'} whiteSpace={'nowrap'}>
        Per Page
      </Text>
      <NativeSelectRoot borderRadius="l1" w={'72px'} cursor="pointer">
        <NativeSelectField
          value={size}
          onChange={({ target: { value } }) => {
            const newSize = parseInt(value, 10);
            onSizeChange(1, newSize);
          }}
        >
          {options.map(option => (
            <option key={option.value}>{option.label}</option>
          ))}
        </NativeSelectField>
      </NativeSelectRoot>
    </Flex>
  );
};
