import { Skeleton, Stack, Tabs } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getDashboardOnboardingStepStatus, ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { usePaywall } from 'app/acl/paywall';
import AppHeader from 'app/app-header';
import { Tooltip } from 'components/ui/tooltip';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BillingPlanEnum } from 'schema/types-schema.d';
import { OnBoardingStepStatus } from 'types/onboarding';

import { APIKeys } from './components/api-keys/api-keys';
import BankDetails from './components/bank-details';
import BillingDetails from './components/billing-details';
import { Communications } from './components/communications/communications';
import { OrgSettings } from './components/org-settings';
import OrgSetup from './components/org-setup';
import { Users } from './components/users/users';

function Settings() {
  const { tab = 'organization-setup' } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const { orgId, isTest } = useOrg();
  const { billingPlan } = usePaywall();
  const location = useLocation();
  const [, setSearchParams] = useQueryParam('');
  const queryClient = useQueryClient();

  // Force fetch onboarding status when component mounts
  useEffect(() => {
    if (orgId) {
      queryClient.fetchQuery({
        queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
        queryFn: async () => {
          const response = await getDashboardOnboardingStepStatus(orgId);
          return response.data satisfies OnBoardingStepStatus;
        },
      });
    }
  }, [orgId, queryClient]);

  const { data: isOrgSetupDone, isPending } = useQuery({
    queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
    queryFn: async () => {
      const response = await getDashboardOnboardingStepStatus(orgId);
      return response.data satisfies OnBoardingStepStatus;
    },
    select: data => {
      return Object.values({
        bank_details_status: data.bank_details_status,
        organization_details_status: data.organization_details_status,
      }).every(value => value === true);
    },
    enabled: Boolean(orgId),
    staleTime: 0,
    refetchOnMount: true,
  });

  const tabs = {
    byId: {
      'organization-details': {
        title: 'Organization Details',
        value: 'organization-details',
        content: (
          <Stack w={{ sm: '100%', md: '100%' }} p={2} borderRadius={4} key="setup-org-tab">
            <OrgSetup
              onSetupDone={() => {
                handleTabsChange('organization-details');
              }}
            />
          </Stack>
        ),
      },
      'bank-details': {
        title: 'Bank Details',
        value: 'bank-details',
        content: (
          <Stack>
            <BankDetails />
          </Stack>
        ),
      },
      'billing-details': {
        title: 'Billing Details',
        value: 'billing-details',
        content: (
          <Stack>
            <BillingDetails />
          </Stack>
        ),
      },
      kintsugimail: {
        title: 'KintsugiMail',
        value: 'kintsugimail',
        content: (
          <Stack>
            <Communications />
          </Stack>
        ),
      },
      users: {
        title: 'Users',
        value: 'users',
        content: (
          <Stack>
            <Users />
          </Stack>
        ),
      },
      'api-keys': {
        title: 'API Keys',
        value: 'api-keys',
        content: (
          <Stack>
            <APIKeys />
          </Stack>
        ),
      },
      settings: {
        title: 'Settings',
        value: 'settings',
        content: (
          <Stack key="org-settings-tab" w="100%">
            <OrgSettings />,
          </Stack>
        ),
      },
    },
    ids: ['organization-details', 'bank-details', 'billing-details', 'kintsugimail', 'users', 'api-keys', 'settings'],
  };

  const handleTabsChange = (value: string) => {
    navigate(`/configurations/${value}`);
  };

  // Add dependency array to useEffect to prevent infinite loops and default tab navigation
  useEffect(() => {
    if (!tab || !tabs.ids.includes(tab)) {
      navigate('/configurations/organization-details', { replace: true });
    }
  }, [tab, navigate]);

  // Show loading state while checking org setup status
  if (isPending) {
    return <Skeleton height="100%" width="60.75rem" />;
  }

  const isCommunicationsLocked = billingPlan === BillingPlanEnum.FREE;
  const isApiKeysLocked = !isTest && ![BillingPlanEnum.PREMIUM, BillingPlanEnum.GROWTH].includes(billingPlan);

  const disabledTabs: { value: string; tooltipText: string }[] = [];

  const currentPath = location.pathname;

  if (!isOrgSetupDone) {
    disabledTabs.push({
      value: 'billing-details',
      tooltipText: 'Please complete the onboarding to unlock this tab.',
    });
    if (billingPlan != BillingPlanEnum.FREE) {
      disabledTabs.push({
        value: 'kintsugimail',
        tooltipText: 'Please complete the onboarding to unlock this tab',
      });
    }
  }

  if (isCommunicationsLocked) {
    disabledTabs.push({
      value: 'kintsugimail',
      tooltipText: 'Please upgrade to a paid plan to unlock this tab.',
    });
  }

  if (isApiKeysLocked) {
    disabledTabs.push({
      value: 'api-keys',
      tooltipText: 'Please upgrade to a paid plan to unlock this tab.',
    });
  }

  if (
    (!isOrgSetupDone && (currentPath.includes('billing-details') || currentPath.includes('kintsugimail'))) ||
    (isCommunicationsLocked && currentPath.includes('kintsugimail')) ||
    (isApiKeysLocked && currentPath.includes('api-keys'))
  ) {
    handleTabsChange('organization-setup');
  }

  const handleTabClick = (tab: string) => {
    //kintsugiMail has index 3 check if kintsugiMail is locked before handling tab change
    //apiKeys has index 5 check if apiKeys is locked before handling tab change
    const isLockedTab = (tab === 'kintsugimail' && isCommunicationsLocked) || (tab === 'api-keys' && isApiKeysLocked);

    if (isLockedTab) {
      setSearchParams({ openPricingModal: 'true' });
    } else {
      handleTabsChange(tab);
    }
  };

  return (
    <>
      <AppHeader />
      <Stack h="100%">
        <Tabs.Root
          value={tab}
          onValueChange={({ value }) => {
            handleTabClick(value);
          }}
          colorPalette="blue"
        >
          <Tabs.List
            flexWrap={{
              base: 'wrap',
              sm: 'wrap',
              md: 'nowrap',
            }}
          >
            {tabs.ids.map(key => {
              const typedKey = key as keyof typeof tabs.byId;
              const disabledItem = disabledTabs.find(({ value }) => value === typedKey);
              return (
                <Tooltip key={key} content={disabledItem?.tooltipText} disabled={!disabledItem} portalled={true}>
                  <Tabs.Trigger _selected={{ color: 'secondary.500' }} value={key} disabled={!!disabledItem}>
                    {tabs.byId[typedKey].title}
                  </Tabs.Trigger>
                </Tooltip>
              );
            })}
          </Tabs.List>
          {tabs.ids.map(key => {
            const typedKey = key as keyof typeof tabs.byId;
            const { value, content } = tabs.byId[typedKey];
            return (
              <Tabs.Content key={value} value={value}>
                {content}
              </Tabs.Content>
            );
          })}
        </Tabs.Root>
      </Stack>
    </>
  );
}

export default Settings;
