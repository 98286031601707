import { Badge, Table, Text } from '@chakra-ui/react';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { USER_ROLES } from 'constants/users';
import { UserRoleUpdateRequest } from 'types/shared-types';

type UserTableRowProps = {
  email: string;
  role?: string;
  status: 'Active' | 'Inactive' | 'Pending';
  canUpdateRole: boolean;
  userId: string;
  updateUserRole: (payload: UserRoleUpdateRequest) => void;
};

export const UserTableRow = ({ email, role, status, canUpdateRole, userId, updateUserRole }: UserTableRowProps) => {
  return (
    <Table.Row>
      <Table.Cell width="40%">
        <Text>{email}</Text>
      </Table.Cell>
      <Table.Cell width="30%">
        {!canUpdateRole ? (
          <Text>{role ?? ''}</Text>
        ) : (
          <NativeSelectRoot>
            <NativeSelectField
              id="role"
              name="role"
              value={role}
              onChange={({ target: { value: role } }) => updateUserRole({ userId, role })}
              placeholder="Select"
            >
              {USER_ROLES.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        )}
      </Table.Cell>
      <Table.Cell>
        <Badge w="3.75rem" margin="0 auto" colorPalette={status === 'Active' ? 'green' : 'gray'}>
          {status}
        </Badge>
      </Table.Cell>
    </Table.Row>
  );
};
