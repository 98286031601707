import { Flex, Table, Text } from '@chakra-ui/react';
import { toDateShort } from 'utils/dates';

import { APIKeyMenu } from './api-key-menu';

type APIKeyTableRowProps = {
  id: string;
  createdAt: number;
  expiresAt?: number;
  handleDeleteApiKey: (id: string) => void;
};

export const APIKeyTableRow = ({ id, createdAt, expiresAt, handleDeleteApiKey }: APIKeyTableRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Text>{id}...</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{toDateShort(new Date(createdAt * 1000))}</Text>
      </Table.Cell>
      <Table.Cell width="15rem">
        <Flex align="center" justify="space-between">
          <Text>{expiresAt ? toDateShort(new Date(expiresAt * 1000)) : 'Never'}</Text>

          <APIKeyMenu id={id} handleDeleteApiKey={handleDeleteApiKey} />
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};
