import { Box, Flex, Link, List, ListIndicator, Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { updateBillingPlan } from 'apis/billing-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import debounce from 'lodash/debounce';
import { MdCheck } from 'react-icons/md';

type UpgradeBillingPlanFromGrowthModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpgradeSuccess: () => void;
};

const features = ['CPA on demand', 'Unlimited states', 'Slack support', 'SSO'];

export const UpgradeBillingPlanFromGrowthModal = ({
  isOpen,
  onClose,
  onUpgradeSuccess,
}: UpgradeBillingPlanFromGrowthModalProps) => {
  const { orgId } = useOrg();
  const { track } = useTracking();

  const { mutateAsync: doUpdateBillingPlan, isPending } = useMutation({
    mutationFn: async () => {
      return await updateBillingPlan(orgId, 'PREMIUM');
    },
    onSuccess: data => {
      if (data) {
        track('user upgraded billing plan', {
          updated_billing_plan: 'PREMIUM',
        });
        onUpgradeSuccess();
        onClose();
      }
    },
  });
  const debouncedDoUpdateBillingPlan = debounce(doUpdateBillingPlan, 500);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>
          <DialogTitle>Upgrade to Premium - $1500/month</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <List.Root gap={2} w="full">
            {features.map((feature, index) => (
              <List.Item key={index} w={'max-content'}>
                <ListIndicator asChild fontSize={'1.5rem'} color="#4285F4">
                  <MdCheck />
                </ListIndicator>
                {feature}
              </List.Item>
            ))}
          </List.Root>
        </DialogBody>
        <Box pb={2} ml={6}>
          <Text fontSize={'xs'}>The Premium plan is billed in arrears on the first day of the next month</Text>
        </Box>
        <DialogFooter justifyContent={'space-between'}>
          <Text>
            Questions?
            <Link ml={1} color="#4285F4" href="mailto:growth@trykintsugi.com">
              Contact Sales
            </Link>
          </Text>
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={isPending}
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => debouncedDoUpdateBillingPlan()}
            >
              Upgrade
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
