import { createListCollection, Flex, Icon, Input, SimpleGrid, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addOrUpdateBusinessInformation, ORGANIZATION_SERVICE_QUERY_KEYS } from 'apis/organizations-apis';
import { AxiosError } from 'axios';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useMemo, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { LocationOptionType } from 'types/location';
import { AccountingModel, CountryCodeEnum, OrgDetailsInstance } from 'types/shared-types';
import { replaceNullWithEmptyString } from 'utils';
import { accountingModel } from 'utils/enum-helpers';
import { getMaxLengthMessage } from 'utils/utils';
import { object, string } from 'yup';

const validationSchema = object().shape({
  business_phone: string().required('Phone Number required').max(200, getMaxLengthMessage('Business Phone', 200)),
  business_email: string()
    .email('Invalid email address')
    .required('Email required')
    .max(100, getMaxLengthMessage('Business Email', 100)),
  business_address_1: string()
    .required('Business Address required')
    .max(200, getMaxLengthMessage('Business Address', 200)),
  business_city: string().required('Business City required').max(200, getMaxLengthMessage('Business City', 200)),
  business_country_code: string(),
  business_state: string().required('Business State required').max(200, getMaxLengthMessage('Business State', 200)),
  business_postal_code: string()
    .required('Business Address Postal Code required')
    .max(100, getMaxLengthMessage('Business Postal Code', 100)),
  business_mailing_address_1: string()
    .required('Business Mailing Address required')
    .max(200, getMaxLengthMessage('Business Mailing Address', 200)),
  business_mailing_city: string()
    .required('Business Mailing City required')
    .max(100, getMaxLengthMessage('Business Mailing City', 100)),
  business_mailing_country_code: string(),
  business_mailing_state: string()
    .required('Business Mailing State required')
    .max(100, getMaxLengthMessage('Business Mailing State', 100)),
  business_mailing_postal_code: string()
    .required('Business Mailing Postal Code required')
    .max(100, getMaxLengthMessage('Business Mailing Postal Code', 100)),
  business_fiscal_year_end: string().required('Business Fiscal Year End required'),
});

const BUSINESS_INFO_FIELDS = [
  'business_phone',
  'business_email',
  'business_address_1',
  'business_address_2',
  'business_city',
  'business_state',
  'business_postal_code',
  'business_country_code',
  'business_fiscal_year_end',
  'business_mailing_address_1',
  'business_mailing_address_2',
  'business_mailing_city',
  'business_mailing_state',
  'business_mailing_postal_code',
  'business_mailing_country_code',
  'accounting_model',
];

const INITIAL_FORM_VALUE = {
  id: '',
  business_phone: '',
  business_email: '',
  business_fiscal_year_end: '',
  business_address_1: '',
  business_address_2: '',
  business_city: '',
  business_country_code: '' as CountryCodeEnum | null,
  business_state: '',
  business_postal_code: '',
  business_mailing_address_1: '',
  business_mailing_address_2: '',
  business_mailing_city: '',
  business_mailing_state: '',
  business_mailing_postal_code: '',
  business_mailing_country_code: '' as CountryCodeEnum | null,
  accounting_model: AccountingModel.ACCRUAL as AccountingModel | null,
};
/**
 * Handles the business information edit. step 2 of org-details.
 */
const BusinessInformationEdit = ({
  orgDetails,
  goNext,
  isOrgDetailsLoading,
}: {
  orgDetails?: OrgDetailsInstance;
  goNext: () => void;
  isOrgDetailsLoading?: boolean;
}) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const { countries, getStates } = useLocationData();

  const [businessStateOptions, setBusinessStateOptions] = useState<LocationOptionType[]>([]);
  const [businessMailingStateOptions, setBusinessMailingStateOptions] = useState<LocationOptionType[]>([]);

  const mutation = useMutation({
    mutationFn: (payload: Partial<OrgDetailsInstance>) => {
      return addOrUpdateBusinessInformation(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.list(orgId)] });
      handleSuccessNotification('Business Information Updated successfully.');
      goNext();
    },
    onError: (err: Error | AxiosError) => {
      handleFailNotification(err);
      console.log(err);
    },
  });

  const { values, setFieldValue, handleChange, handleSubmit, isValid, dirty, errors, touched, setTouched } = useFormik({
    initialValues: replaceNullWithEmptyString(orgDetails) || INITIAL_FORM_VALUE,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: OrgDetailsInstance) => {
      const payload: {
        [key: string]: any;
      } = {};
      const val: {
        [key: string]: any;
      } = { ...values };
      BUSINESS_INFO_FIELDS.forEach(elem => {
        payload[elem] = val[elem] == null ? '' : val[elem];
      });
      mutation.mutate({
        ...payload,
      });
    },
  });

  useEffect(() => {
    const fetchStates = async () => {
      if (values.business_country_code) {
        const businessStates = await getStates(values.business_country_code);
        setBusinessStateOptions(businessStates);
      }
      if (values.business_mailing_country_code) {
        const mailingStates = await getStates(values.business_mailing_country_code);
        setBusinessMailingStateOptions(mailingStates);
      }
    };

    fetchStates();
  }, [getStates, values.business_country_code, values.business_mailing_country_code]);

  const countriesCollection = useMemo(() => {
    return createListCollection({
      items: countries,
    });
  }, [countries]);

  const businessStateCollection = useMemo(() => {
    return createListCollection({
      items: businessStateOptions,
    });
  }, [businessStateOptions]);

  const businessMailingStateCollection = useMemo(() => {
    return createListCollection({
      items: businessMailingStateOptions,
    });
  }, [businessMailingStateOptions]);

  if (isOrgDetailsLoading) {
    return <Skeleton height="100%" width="60.75rem" />;
  }

  return (
    <Stack>
      <Text fontSize={'md'} fontWeight="500">
        Business Information
      </Text>
      <Text fontSize={'sm'} mb={2}>
        Manage basic company information required by the jurisdictions
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} gap={4}>
        <Field
          label="Business Telephone"
          invalid={!!(errors.business_phone && touched.business_phone)}
          errorText={errors.business_phone}
          required
        >
          <Input
            id="business_phone"
            type="business_phone"
            name="business_phone"
            value={values.business_phone ?? ''}
            onChange={handleChange}
          />
          {errors.business_phone && touched.business_phone && <Text color={'#E53E3E'}>{errors.business_phone}</Text>}
        </Field>
        <Field
          label="Business Email Address"
          invalid={!!(errors.business_email && touched.business_email)}
          errorText={errors.business_email}
          required
        >
          <Input
            id="business_email"
            type="email"
            name="business_email"
            value={values.business_email ?? ''}
            onChange={handleChange}
          />
        </Field>
      </SimpleGrid>
      <br />

      <Text fontSize={'1rem'} fontWeight="500">
        Business Address
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} gap={4}>
        <Field label="Country" required>
          <SelectRoot
            collection={countriesCollection}
            value={values.business_country_code ? [values.business_country_code] : undefined}
            onValueChange={({ value }) => {
              setFieldValue('business_country_code', value[0]);
              setFieldValue('business_state', undefined);
            }}
          >
            <SelectTrigger>
              <SelectValueText placeholder="Select" />
            </SelectTrigger>
            <SelectContent portalled={false}>
              {countriesCollection.items.map(item => (
                <SelectItem key={item.value} item={item}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Field>
        <Field
          label="State"
          invalid={!!(errors.business_state && touched.business_state)}
          errorText={errors.business_state}
          required
        >
          <SelectRoot
            collection={businessStateCollection}
            value={values.business_state ? [values.business_state] : undefined}
            onValueChange={({ value }) => {
              setFieldValue('business_state', value[0]);
            }}
          >
            <SelectTrigger>
              <SelectValueText placeholder="Select" />
            </SelectTrigger>
            <SelectContent portalled={false} minWidth={'196px'} maxWidth={'100%'} justifyContent={'flex-start'}>
              {businessStateCollection.items.map(item => (
                <SelectItem key={item.value} item={item}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Field>
        <Field
          label="City"
          invalid={!!(errors.business_city && touched.business_city)}
          errorText={errors.business_city}
          required
        >
          <Input
            id="business_city"
            type="business_city"
            name="business_city"
            value={values.business_city ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Postal Code"
          invalid={!!(errors.business_postal_code && touched.business_postal_code)}
          errorText={errors.business_postal_code}
          required
        >
          <Input
            id="business_postal_code"
            type="business_postal_code"
            name="business_postal_code"
            value={values.business_postal_code ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Address Line 1"
          invalid={!!(errors.business_address_1 && touched.business_address_1)}
          errorText={errors.business_address_1}
          required
        >
          <Input
            id="business_address_1"
            type="business_address_1"
            name="business_address_1"
            value={values.business_address_1 ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Address Line 2"
          invalid={!!(errors.business_address_2 && touched.business_address_2)}
          errorText={errors.business_address_2}
        >
          <Input
            id="business_address_2"
            type="business_address_2"
            name="business_address_2"
            value={values.business_address_2 ?? ''}
            onChange={handleChange}
          />
        </Field>
      </SimpleGrid>

      <Text fontSize={'md'} fontWeight="medium" mt={4}>
        Mailing Address
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} gap={4}>
        <Field label="Country" required>
          <SelectRoot
            collection={countriesCollection}
            value={values.business_mailing_country_code ? [values.business_mailing_country_code] : undefined}
            onValueChange={({ value }) => {
              setFieldValue('business_mailing_country_code', value[0]);
              setFieldValue('business_mailing_state', undefined);
            }}
          >
            <SelectTrigger>
              <SelectValueText placeholder="Select" />
            </SelectTrigger>
            <SelectContent portalled={false}>
              {countriesCollection.items.map(item => (
                <SelectItem key={item.value} item={item}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Field>
        <Field
          label="State"
          invalid={!!(errors.business_mailing_state && touched.business_mailing_state)}
          errorText={errors.business_mailing_state}
          required
        >
          <SelectRoot
            collection={businessMailingStateCollection}
            value={values.business_mailing_state ? [values.business_mailing_state] : undefined}
            onValueChange={({ value }) => {
              setFieldValue('business_mailing_state', value[0]);
            }}
          >
            <SelectTrigger>
              <SelectValueText placeholder="Select" />
            </SelectTrigger>
            <SelectContent portalled={false} minWidth={'196px'} maxWidth={'100%'} justifyContent={'flex-start'}>
              {businessMailingStateCollection.items.map(item => (
                <SelectItem key={item.value} item={item}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Field>
        <Field
          label="City"
          invalid={!!(errors.business_mailing_city && touched.business_city)}
          errorText={errors.business_mailing_city}
          required
        >
          <Input
            id="business_mailing_city"
            type="business_mailing_city"
            name="business_mailing_city"
            value={values.business_mailing_city ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Postal Code"
          invalid={!!(errors.business_mailing_postal_code && touched.business_mailing_postal_code)}
          errorText={errors.business_mailing_postal_code}
          required
        >
          <Input
            id="business_mailing_postal_code"
            type="business_mailing_postal_code"
            name="business_mailing_postal_code"
            value={values.business_mailing_postal_code ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Address Line 1"
          invalid={!!(errors.business_mailing_address_1 && touched.business_mailing_address_1)}
          errorText={errors.business_mailing_address_1}
          required
        >
          <Input
            id="business_mailing_address_1"
            type="business_mailing_address_1"
            name="business_mailing_address_1"
            value={values.business_mailing_address_1 ?? ''}
            onChange={handleChange}
          />
          {errors.business_mailing_address_1 && touched.business_mailing_address_1 && (
            <Text color={'#E53E3E'}>{errors.business_mailing_address_1}</Text>
          )}
        </Field>
        <Field
          label="Address Line 2"
          invalid={!!(errors.business_mailing_address_2 && touched.business_mailing_address_2)}
          errorText={errors.business_mailing_address_2}
        >
          <Input
            id="business_mailing_address_2"
            type="business_mailing_address_2"
            name="business_mailing_address_2"
            value={values.business_mailing_address_2 ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label={
            <Flex alignItems={'center'} gap="1">
              <Text>Fiscal Year End</Text>
              <Tooltip content={`Enter your latest completed Fiscal Year End date.`}>
                <Icon color="#4B5169" size="sm">
                  <MdInfo />
                </Icon>
              </Tooltip>
            </Flex>
          }
          invalid={!!(errors.business_fiscal_year_end && touched.business_fiscal_year_end)}
          errorText={errors.business_fiscal_year_end}
          required
        >
          <DatePicker
            selected={values.business_fiscal_year_end ?? ''}
            onChange={date => {
              setFieldValue('business_fiscal_year_end', date);
            }}
          />
        </Field>
        <Field
          label="Business Accounting Method"
          invalid={!!(errors.accounting_model && touched.accounting_model)}
          errorText={String(errors.accounting_model)}
          required
        >
          <NativeSelectRoot>
            <NativeSelectField
              name="accounting_model"
              onChange={handleChange}
              value={values.accounting_model ?? AccountingModel.ACCRUAL}
            >
              {accountingModel.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>

          {errors.accounting_model && touched.accounting_model && (
            <Text color={'#E53E3E'}>{String(errors.accounting_model)}</Text>
          )}
        </Field>
      </SimpleGrid>
      <Flex justify={'flex-end'}>
        <Button
          loading={mutation.isPending}
          disabled={!isValid || !dirty}
          width={'7rem'}
          alignSelf={'top'}
          onClick={() => {
            if (isValid) {
              handleSubmit();
            } else {
              setTouched({
                business_phone: true,
                business_email: true,
                business_address_1: true,
                business_city: true,
                business_state: true,
                business_postal_code: true,
                business_fiscal_year_end: true,
                business_mailing_address_1: true,
                business_mailing_city: true,
                business_mailing_state: true,
                business_mailing_postal_code: true,
              });
            }
          }}
        >
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default BusinessInformationEdit;
