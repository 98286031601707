import { Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useSearchParams } from 'react-router-dom';

type SetupPhysicalMailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  nexusId?: string;
  isNexus?: boolean;
  isVdaEligible?: boolean;
};

const primaryIdLink = 'https://support.usestable.com/en/articles/8236386-what-is-a-primary-id';
const secondaryIdLink = 'https://support.usestable.com/en/articles/8236383-what-is-a-secondary-id';
const verificationLink = 'https://support.usestable.com/en/articles/8236407-how-do-i-take-an-id-verification-image';

export const SetupPhysicalMailModal = ({
  isOpen,
  onClose,
  nexusId,
  isNexus,
  isVdaEligible,
}: SetupPhysicalMailModalProps) => {
  const [, setSearchParams] = useSearchParams();

  const handleCreatePhysicalMailAddress = () => {
    const newTab = window.open('/stableform', '_blank');

    onClose();

    if (!newTab) {
      alert('Popup blocked. Please allow popups for this site.');
    }
  };
  const handleClose = () => {
    const params: Record<string, string> = { requestRegistration: 'true' };

    if (nexusId) {
      params.nexusId = nexusId;
      if (isVdaEligible) {
        params.vda = 'true';
      }
    } else if (!isNexus) {
      // Don't set any params if not nexus
      onClose();
      return;
    }

    setSearchParams(params);
    onClose();
  };

  return (
    <DialogRoot size={'lg'} closeOnInteractOutside={false} open={isOpen}>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger onClick={onClose} />
        <DialogHeader>
          <DialogTitle>
            {isNexus || nexusId ? 'Setup KinstsugiMail for free' : 'Required Documents for Physical Mail Setup'}
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Text>
            You’ll need the following documents to complete your identity verification. The process will take about 8-10
            minutes.
          </Text>
          <Stack my={4} mx={2}>
            <HStack>
              <Text fontSize={'lg'}>•</Text>
              <Text
                fontWeight={'500'}
                textDecoration={'underline'}
                onClick={() => {
                  window.open(primaryIdLink, '_blank');
                }}
                cursor={'pointer'}
              >
                Primary ID
              </Text>
              <Text fontSize={'12px'}>(Government-issued photo ID)</Text>
            </HStack>
            <HStack>
              <Text fontSize={'lg'}>•</Text>
              <Text
                fontWeight={'500'}
                textDecoration={'underline'}
                onClick={() => {
                  window.open(secondaryIdLink, '_blank');
                }}
                cursor={'pointer'}
              >
                Secondary ID
              </Text>
              <Text fontSize={'12px'}>(Proof of address)</Text>
            </HStack>
            <HStack>
              <Text fontSize={'lg'}>•</Text>
              <Text
                fontWeight={'500'}
                textDecoration={'underline'}
                onClick={() => {
                  window.open(verificationLink, '_blank');
                }}
                cursor={'pointer'}
              >
                Verification Photo
              </Text>
              <Text fontSize={'12px'}>(Selfie)</Text>
            </HStack>
          </Stack>
          <Text>
            KintsugiMail allows you to receive physical mails from jurisdictions as emails to your mail group.
          </Text>
        </DialogBody>
        <DialogFooter>
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={handleClose}>
              Setup Later
            </Button>
            <Button variant={'solid'} width={'90px'} onClick={handleCreatePhysicalMailAddress}>
              Setup Now
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
