import { Box, createListCollection, Grid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getOrgBillingHistoryChartData } from 'apis/billing-apis';
import { Chart } from 'components/ui/chart';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { useOrg } from 'hooks/useOrg';
import { useMemo, useState } from 'react';
import { BillingFrequencyEnum } from 'types/billings';
import { ChartData } from 'types/charts';
import {
  findMonthIndex,
  generateYearRange,
  getLineChartOptions,
  initializeMonthlyDataPoints,
  initializeYearlyDataPoints,
} from 'utils/charts';

const YEARS_TO_SHOW = 5;
const CHART_FILTER_OPTIONS = [
  { label: 'Monthly', value: BillingFrequencyEnum.MONTHLY },
  { label: 'Yearly', value: BillingFrequencyEnum.YEARLY },
];

const DEFAULT_CHART_DATA: ChartData = {
  series: [],
  options: getLineChartOptions('datetime'),
};

const getChartBaseOptions = () => ({
  ...getLineChartOptions('category'),
  chart: {
    zoom: { enabled: false },
    toolbar: { show: false },
  },
});

export const BillingHistoryChart = () => {
  const { orgId } = useOrg();
  const [selectedFilter, setSelectedFilter] = useState<BillingFrequencyEnum>(BillingFrequencyEnum.MONTHLY);

  const transformYearlyData = (data: any): ChartData => {
    const years = generateYearRange(YEARS_TO_SHOW);
    const yearlyData = initializeYearlyDataPoints(years);

    data.labels.forEach((year: string, index: number) => {
      const yearIndex = years.findIndex(y => y.toString() === year);
      if (yearIndex !== -1) {
        yearlyData[yearIndex].y = Number(data.values[index]);
      }
    });

    return {
      series: [
        {
          name: 'Yearly',
          data: yearlyData,
        },
      ],
      options: getChartBaseOptions(),
    };
  };

  const transformMonthlyData = (data: any): ChartData => {
    const monthlyData = initializeMonthlyDataPoints();

    data.labels.forEach((month: string, index: number) => {
      const monthIndex = findMonthIndex(month);
      if (monthIndex !== -1) {
        monthlyData[monthIndex].y = Number(data.values[index]);
      }
    });

    return {
      series: [
        {
          name: 'Monthly',
          data: monthlyData,
        },
      ],
      options: getChartBaseOptions(),
    };
  };

  const { data: billingChartData = DEFAULT_CHART_DATA, isLoading } = useQuery({
    queryKey: ['billing-history-chart', orgId, selectedFilter],
    queryFn: () => getOrgBillingHistoryChartData(orgId, selectedFilter),
    select: (data): ChartData =>
      selectedFilter === BillingFrequencyEnum.YEARLY ? transformYearlyData(data) : transformMonthlyData(data),
  });

  const chartFilterCollection = useMemo(
    () => createListCollection({ items: CHART_FILTER_OPTIONS }),
    [CHART_FILTER_OPTIONS]
  );

  return (
    <Grid templateColumns={'1fr'} gap={4}>
      <Chart
        type="line"
        height={'373px'}
        heading="Overview"
        series={billingChartData.series}
        options={billingChartData.options}
        isLoading={isLoading}
        headerRight={
          <Box width={'100px'}>
            <SelectRoot
              collection={chartFilterCollection}
              value={selectedFilter ? [selectedFilter] : undefined}
              onValueChange={({ value }) => {
                setSelectedFilter(value[0] as BillingFrequencyEnum);
              }}
            >
              <SelectTrigger>
                <SelectValueText />
              </SelectTrigger>
              <SelectContent>
                {chartFilterCollection.items.map(item => (
                  <SelectItem key={item.value} item={item}>
                    {item.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </Box>
        }
      />
    </Grid>
  );
};
