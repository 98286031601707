import { DialogFooter, DialogHeader, DialogRoot, HStack, Input } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { inviteUserToOrg, USERS_AND_INVITES_STATE_KEY } from 'apis/organizations-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { USER_ROLES } from 'constants/users';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserInviteRequest } from 'types/shared-types';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  role: Yup.string().required('Role is required'),
});

export const InviteUser = () => {
  const { orgId } = useOrg();
  const { handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const initialRef = useRef<HTMLInputElement>(null);
  const isOpen = searchParams.get('invite') === 'true';

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (payload: UserInviteRequest) => {
      const { data } = await inviteUserToOrg(orgId, payload);
      return data;
    },
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({ queryKey: [USERS_AND_INVITES_STATE_KEY, orgId] });
      handleSuccessNotification(message);
      toggleInviteModal();
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    errors,
    touched,
    isValid,
    values: { email, role },
  } = useFormik({
    initialValues: {
      email: '',
      role: '',
    },
    validationSchema,
    onSubmit: async values => {
      const result = await mutateAsync(values);
      if (result) {
        resetForm();
      }
    },
  });

  const toggleInviteModal = useCallback(() => {
    resetForm();
    setSearchParams(params => {
      if (params.has('invite')) {
        params.delete('invite');
      } else {
        params.set('invite', 'true');
      }
      return params;
    });
  }, [setSearchParams]);

  return (
    <DialogRoot open={isOpen} initialFocusEl={() => initialRef.current}>
      <DialogTrigger asChild>
        <Button w="6.25rem" onClick={toggleInviteModal}>
          Invite
        </Button>
      </DialogTrigger>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger onClick={toggleInviteModal} />
        <DialogHeader>
          <DialogTitle>Invite User</DialogTitle>
        </DialogHeader>

        <DialogBody pb={6}>
          <HStack gap="4">
            <Field label="Email" invalid={!!(errors.email && touched.email)} errorText={errors.email} required>
              <Input
                ref={initialRef}
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Field>

            <Field label="Role" invalid={!!(errors.role && touched.role)} errorText={errors.role} required>
              <NativeSelectRoot>
                <NativeSelectField
                  id="role"
                  name="role"
                  value={role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Select"
                >
                  {USER_ROLES.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </NativeSelectField>
              </NativeSelectRoot>
            </Field>
          </HStack>
        </DialogBody>

        <DialogFooter>
          <Button variant="outline" onClick={toggleInviteModal}>
            Cancel
          </Button>
          <Button
            disabled={!isValid || isPending}
            loading={isPending}
            w="6.25rem"
            variant="solid"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
