import {
  Flex,
  Icon,
  Input,
  Link,
  NativeSelectField,
  Separator,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { addOrUpdateBusinessOwners, ORGANIZATION_SERVICE_QUERY_KEYS } from 'apis/organizations-apis';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import { Field } from 'components/ui/field';
import { NativeSelectRoot } from 'components/ui/native-select';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useEffect, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { LocationOptionType } from 'types/location';
import { OrgDetailsInstance } from 'types/shared-types';
import { replaceNullWithEmptyString } from 'utils';
import { getMaxLengthMessage } from 'utils/utils';
import * as Yup from 'yup';

const BusinessOwnerForm = ({
  index,
  parentFormik,
  isOrgDetailsLoading,
}: {
  index: number;
  parentFormik: any;
  isOrgDetailsLoading?: boolean;
}) => {
  const { countries, getStates } = useLocationData();
  const [owner1StateOptions, setOwner1StateOptions] = useState<LocationOptionType[]>([]);
  const [owner2StateOptions, setOwner2StateOptions] = useState<LocationOptionType[]>([]);
  const [owner3StateOptions, setOwner3StateOptions] = useState<LocationOptionType[]>([]);

  const name = `business_owner${index}_name`;
  const phone = `business_owner${index}_phone`;
  const email = `business_owner${index}_email`;
  const dob = `business_owner${index}_dob`;
  const title = `business_owner${index}_title`;
  const address_1 = `business_owner${index}_address_1`;
  const address_2 = `business_owner${index}_address_2`;
  const city = `business_owner${index}_city`;
  const state = `business_owner${index}_state`;
  const postal_code = `business_owner${index}_postal_code`;
  const ssn = `business_owner${index}_ssn`;
  const dl = `business_owner${index}_dl`;
  const country_code = `business_owner${index}_country_code`;
  const percent_ownership = `business_owner${index}_percent_ownership`;

  const formik = parentFormik;

  useEffect(() => {
    const fetchStates = async () => {
      if (index === 1 && formik.values[country_code]) {
        const states = await getStates(formik.values[country_code]);
        setOwner1StateOptions(states);
      }
      if (index === 2 && formik.values[country_code]) {
        const states = await getStates(formik.values[country_code]);
        setOwner2StateOptions(states);
      }
      if (index === 3 && formik.values[country_code]) {
        const states = await getStates(formik.values[country_code]);
        setOwner3StateOptions(states);
      }
    };
    fetchStates();
  }, [getStates, formik.values[country_code], index]);

  if (isOrgDetailsLoading) {
    return <Skeleton height="100%" width="60.75rem" />;
  }

  const getStateOptions = (index: number) => {
    switch (index) {
      case 1:
        return owner1StateOptions;
      case 2:
        return owner2StateOptions;
      case 3:
        return owner3StateOptions;
      default:
        return [];
    }
  };

  return (
    <>
      <Separator />
      <Text fontSize={'1rem'} fontWeight="500">
        Owner {index}
      </Text>
      <Stack>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} gap={4}>
          <Field
            label="Name"
            invalid={!!(formik.errors[name] && formik.touched[name])}
            errorText={formik.errors[name]}
            required
          >
            <Input id={name} type={name} name={name} value={formik.values[name]} onChange={formik.handleChange} />
          </Field>{' '}
          <Field
            label="Email"
            invalid={!!(formik.errors[email] && formik.touched[email])}
            errorText={formik.errors[email]}
            required
          >
            <Input id={email} type={email} name={email} value={formik.values[email]} onChange={formik.handleChange} />
            {formik.errors[email] && formik.touched[email] && <Text color={'#E53E3E'}>{formik.errors[email]}</Text>}
          </Field>
          <Field
            label="Date of Birth"
            invalid={!!(formik.errors[dob] && formik.touched[dob])}
            errorText={formik.errors[dob]}
            required
          >
            <DatePicker
              selected={formik.values[dob]}
              onChange={date => {
                formik.setFieldValue(dob, date);
              }}
            />
          </Field>
          <Field
            label="Title"
            invalid={!!(formik.errors[title] && formik.touched[title])}
            errorText={formik.errors[title]}
            required
          >
            <Input id={title} type="text" name={title} value={formik.values[title]} onChange={formik.handleChange} />
          </Field>
          <Field
            label="Phone Number"
            invalid={!!(formik.errors[phone] && formik.touched[phone])}
            errorText={formik.errors[phone]}
            required
          >
            <Input id={phone} type="phone" name={phone} value={formik.values[phone]} onChange={formik.handleChange} />
          </Field>
          <Field label="Country" required>
            <NativeSelectRoot>
              <NativeSelectField
                name={country_code}
                placeholder="Select Country"
                onChange={formik.handleChange}
                value={formik.values[country_code]}
              >
                {countries.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
          </Field>
          <Field
            label="State"
            invalid={!!(formik.errors[state] && formik.touched[state])}
            errorText={formik.errors[state]}
            required
          >
            <NativeSelectRoot>
              <NativeSelectField
                name={state}
                placeholder="Select State"
                onChange={formik.handleChange}
                value={formik.values[state]}
              >
                {getStateOptions(index).map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
            {formik.errors[state] && formik.touched[state] && <Text color={'#E53E3E'}>{formik.errors[state]}</Text>}
          </Field>
          <Field
            label="City"
            invalid={!!(formik.errors[city] && formik.touched[city])}
            errorText={formik.errors[city]}
            required
          >
            <Input id={city} type={city} name={city} value={formik.values[city]} onChange={formik.handleChange} />
          </Field>
          <Field
            label="Postal Code"
            invalid={!!(formik.errors[postal_code] && formik.touched[postal_code])}
            errorText={formik.errors[postal_code]}
            required
          >
            <Input
              id={postal_code}
              type={postal_code}
              name={postal_code}
              value={formik.values[postal_code]}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="Address Line 1"
            invalid={!!(formik.errors[address_1] && formik.touched[address_1])}
            errorText={formik.errors[address_1]}
            required
          >
            <Input
              id={address_1}
              type={address_1}
              name={address_1}
              value={formik.values[address_1]}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="Address Line 2"
            invalid={!!(formik.errors[address_2] && formik.touched[address_2])}
            errorText={formik.errors[address_2]}
          >
            <Input
              id={address_2}
              type={address_2}
              name={address_2}
              value={formik.values[address_2]}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label={
              <Flex alignItems={'center'} gap="1">
                <Text>Social Security Number (SSN)</Text>
                <Tooltip content={`Enter NA if you do not have an SSN.`}>
                  <Icon color="#4B5169" size="sm">
                    <MdInfo />
                  </Icon>
                </Tooltip>
              </Flex>
            }
            invalid={!!(formik.errors[ssn] && formik.touched[ssn])}
            errorText={formik.errors[ssn]}
            required
          >
            <Input id={ssn} type={ssn} name={ssn} value={formik.values[ssn]} onChange={formik.handleChange} />
          </Field>
          <Field
            label={
              <Flex alignItems={'center'} gap="1">
                <Text>Drivers License (DLN)</Text>
                <Tooltip
                  content={`A Driver's License Number (DLN) is a unique identifier on your driver's
                license, issued by the state's licensing agency. Find it on the front
                of your driver's license, typically listed near your photo or personal
                information. Enter NA if you do not have one.`}
                >
                  <Icon color="#4B5169" size="sm">
                    <MdInfo />
                  </Icon>
                </Tooltip>
              </Flex>
            }
            required
            invalid={!!(formik.errors[dl] && formik.touched[dl])}
            errorText={formik.errors[dl]}
          >
            <Input id={dl} type={dl} name={dl} value={formik.values[dl]} onChange={formik.handleChange} />
          </Field>
          <Field
            label={
              <Flex alignItems={'center'} gap="1">
                <Text>Percent Company Ownership</Text>
                <Tooltip
                  content={`Enter the numerical percentage of ownership. For example, if Person 1 owns 60% of the company, enter 60.`}
                >
                  <Icon color="#4B5169" size="sm">
                    <MdInfo />
                  </Icon>
                </Tooltip>
              </Flex>
            }
            invalid={!!(formik.errors[percent_ownership] && formik.touched[percent_ownership])}
            errorText={formik.errors[percent_ownership]}
            required
          >
            <Input
              id={percent_ownership}
              type={percent_ownership}
              name={percent_ownership}
              value={formik.values[percent_ownership]}
              onChange={formik.handleChange}
            />
          </Field>
        </SimpleGrid>
      </Stack>
    </>
  );
};

const getValidationSchema = (index: number) => {
  const name = `business_owner${index}_name`;
  const phone = `business_owner${index}_phone`;
  const email = `business_owner${index}_email`;
  const dob = `business_owner${index}_dob`;
  const title = `business_owner${index}_title`;
  const address_1 = `business_owner${index}_address_1`;
  const address_2 = `business_owner${index}_address_2`;
  const city = `business_owner${index}_city`;
  const country_code = `business_owner${index}_country_code`;
  const state = `business_owner${index}_state`;
  const postal_code = `business_owner${index}_postal_code`;
  const ssn = `business_owner${index}_ssn`;
  const dl = `business_owner${index}_dl`;
  const percent_ownership = `business_owner${index}_percent_ownership`;

  const validationSchema = Yup.object().shape({
    [name]: Yup.string().required('Owner Name required').max(200, getMaxLengthMessage('Owner Name', 200)),
    [phone]: Yup.string().required('Phone Number required').max(100, getMaxLengthMessage('Phone Number', 100)),
    [email]: Yup.string()
      .email('Invalid email address')
      .required('Email required')
      .max(200, getMaxLengthMessage('Email', 200)),
    [title]: Yup.string().required('Owners Title required').max(100, getMaxLengthMessage('Owners Title', 100)),
    [address_1]: Yup.string().required('Address required').max(200, getMaxLengthMessage('Address', 200)),
    [city]: Yup.string().required('City required').max(200, getMaxLengthMessage('City', 200)),
    [country_code]: Yup.string().required('Country required'),
    [state]: Yup.string().required('State required').max(200, getMaxLengthMessage('State', 200)),
    [dob]: Yup.string().required('Owners Date Of Birth required'),
    [postal_code]: Yup.string()
      .required('Business Mailing Postal Code required')
      .max(100, getMaxLengthMessage('Business Mailing Postal Code', 100)),
    [ssn]: Yup.string().required('SSN required').max(200, getMaxLengthMessage('SSN', 200)),
    [dl]: Yup.string().required('DLN required').max(200, getMaxLengthMessage('DLN', 200)),
    [percent_ownership]: Yup.string()
      .required('Percent of Ownership required')
      .max(200, getMaxLengthMessage('Percent of Ownership', 200)),
  });
  return {
    validationSchema,
    initialValues: {
      [name]: '',
      [phone]: '',
      [dob]: '',
      [email]: '',
      [address_1]: '',
      [address_2]: '',
      [city]: '',
      [country_code]: '',
      [state]: '',
      [title]: '',
      [postal_code]: '',
      [ssn]: '',
      [dl]: '',
      [percent_ownership]: '',
    },
  };
};

/**
 * Handles the business owner edit. step 4 of org-details.
 */
const BusinessOwnerEdit = ({
  goNext,
  orgDetails,
  isOrgDetailsLoading,
}: {
  orgDetails?: OrgDetailsInstance;
  goNext: () => void;
  isOrgDetailsLoading?: boolean;
}) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const [noOfOwner, setNoOfOwner] = useState(1);
  const { track } = useTracking();
  const name_1 = `business_owner1_name`;
  const phone_1 = `business_owner1_phone`;
  const email_1 = `business_owner1_email`;
  const dob_1 = `business_owner1_dob`;
  const title_1 = `business_owner1_title`;
  const address_1_1 = `business_owner1_address_1`;
  const city_1 = `business_owner1_city`;
  const state_1 = `business_owner1_state`;
  const country_code_1 = `business_owner1_country_code`;
  const postal_code_1 = `business_owner1_postal_code`;
  const ssn_1 = `business_owner1_ssn`;
  const dl_1 = `business_owner1_dl`;
  const percent_ownership_1 = `business_owner1_percent_ownership`;
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: Partial<OrgDetailsInstance>) => {
      return addOrUpdateBusinessOwners(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.list(orgId)] });
      handleSuccessNotification('Business Information Updated successfully.');
      queryClient.invalidateQueries({ queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId] });
      goNext();
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const formik1 = useFormik({
    initialValues: {
      ...getValidationSchema(1).initialValues,
    },
    validationSchema: getValidationSchema(1).validationSchema,
    onSubmit: values => {
      const callAddOrUpdateOrgDetails = async () => {
        const payload: {
          [key: string]: any;
        } = {};
        const val: {
          [key: string]: any;
        } = { ...values };
        formik2;
        formik3;
        Object.keys(formik1.initialValues).forEach(elem => {
          payload[elem] = val[elem] ?? '';
          payload[elem.replace(/owner\d+/, 'owner2')] = formik2.values[elem.replace(/owner\d+/, 'owner2')] || null;
          payload[elem.replace(/owner\d+/, 'owner3')] = formik3.values[elem.replace(/owner\d+/, 'owner3')] || null;
        });

        mutate({
          ...payload,
        });
      };
      callAddOrUpdateOrgDetails();
      track('fills_complete Organization Setup');
    },
  });
  const formik2 = useFormik({
    initialValues: { ...getValidationSchema(2).initialValues },
    validationSchema: Yup.object().shape({}),
    onSubmit: () => {
      //
    },
  });
  const formik3 = useFormik({
    initialValues: { ...getValidationSchema(3).initialValues },
    validationSchema: Yup.object().shape({}),
    onSubmit: () => {
      //
    },
  });

  const submit = () => {
    if (formik1.isValid) {
      formik1.handleSubmit();
    } else {
      formik1.setTouched({
        [name_1]: true,
        [title_1]: true,
        [phone_1]: true,
        [email_1]: true,
        [address_1_1]: true,
        [city_1]: true,
        [state_1]: true,
        [country_code_1]: true,
        [dob_1]: true,
        [postal_code_1]: true,
        [ssn_1]: true,
        [dl_1]: true,
        [percent_ownership_1]: true,
      });
    }
  };

  useEffect(() => {
    if (orgDetails) {
      const sanitizedValues = replaceNullWithEmptyString(orgDetails);
      formik1.setValues(sanitizedValues);
      formik2.setValues(sanitizedValues);
      formik3.setValues(sanitizedValues);

      // Set number of owners based on existing data
      if (sanitizedValues.business_owner1_name) {
        if (sanitizedValues.business_owner3_name || sanitizedValues.business_owner3_email) {
          setNoOfOwner(3);
        } else if (sanitizedValues.business_owner2_name || sanitizedValues.business_owner2_email) {
          setNoOfOwner(2);
        } else {
          setNoOfOwner(1);
        }
      }
    }
  }, [orgDetails]);

  if (isOrgDetailsLoading) {
    return <Skeleton height="100%" width="60.75rem" />;
  }

  return (
    <Stack>
      <Text fontSize={'md'} fontWeight="500">
        Company Owner
      </Text>
      <Text fontSize={'sm'}>Business owner and ownership details</Text>
      <Stack gap={5}>
        <BusinessOwnerForm index={1} parentFormik={formik1} />
        {noOfOwner >= 2 && <BusinessOwnerForm index={2} parentFormik={formik2} />}
        {noOfOwner >= 3 && <BusinessOwnerForm index={3} parentFormik={formik3} />}
      </Stack>
      {noOfOwner < 3 && (
        <Link color="#4285F4" onClick={() => setNoOfOwner(noOfOwner + 1)}>
          + Add Another Owner
        </Link>
      )}
      <Flex justify={'flex-end'}>
        <Button loading={isPending} width={'7rem'} alignSelf={'top'} onClick={submit}>
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default BusinessOwnerEdit;
