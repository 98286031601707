import { Flex, Icon, Input, SimpleGrid, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addOrUpdateBusinessContact, ORGANIZATION_SERVICE_QUERY_KEYS } from 'apis/organizations-apis';
import { AxiosError } from 'axios';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { LocationOptionType } from 'types/location';
import { OrgDetailsInstance } from 'types/shared-types';
import { replaceNullWithEmptyString } from 'utils';
import { getMaxLengthMessage } from 'utils/utils';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  business_contact_name: Yup.string()
    .required('Business Contact Name required')
    .max(200, getMaxLengthMessage('Business Contact Name', 200)),
  business_contact_phone: Yup.string()
    .required('Phone Number required')
    .max(100, getMaxLengthMessage('Business Contact Phone', 100)),
  business_contact_email: Yup.string()
    .email('Invalid email address')
    .required('Email required')
    .max(100, getMaxLengthMessage('Business Contact Email', 100)),
  business_contact_title: Yup.string().required('Business Contact Title required'),
  business_contact_address_1: Yup.string().required('Business Address required'),
  business_contact_city: Yup.string().required('Business City required'),
  business_contact_country_code: Yup.string(),
  business_contact_state: Yup.string().required('Business Contact State required'),
  business_contact_dob: Yup.string().required('Business Contact DOB required'),
  business_contact_postal_code: Yup.string().required('Business Mailing Postal Code required'),
  business_contact_ssn: Yup.string()
    .required('SSN required')
    .max(100, getMaxLengthMessage('Business Contact SSN', 100)),
  business_contact_dl: Yup.string().required('DLN required').max(100, getMaxLengthMessage('Business Contact DL', 100)),
});

const BUSINESS_INFO_FIELDS = [
  'business_contact_name',
  'business_contact_phone',
  'business_contact_email',
  'business_contact_dob',
  'business_contact_title',
  'business_contact_address_1',
  'business_contact_address_2',
  'business_contact_city',
  'business_contact_state',
  'business_contact_postal_code',
  'business_contact_country_code',
  'business_contact_ssn',
  'business_contact_dl',
];

/**
 * Handles the business contact edit. step 3 of org-details.
 */
const BusinessContactEdit = ({
  orgDetails,
  goNext,
  isOrgDetailsLoading,
}: {
  orgDetails?: OrgDetailsInstance;
  goNext: () => void;
  isOrgDetailsLoading?: boolean;
}) => {
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const { countries, getStates, isLoadingCountries } = useLocationData();

  const [stateOptions, setStateOptions] = useState<LocationOptionType[]>([]);

  const mutation = useMutation({
    mutationFn: (payload: Partial<OrgDetailsInstance>) => {
      return addOrUpdateBusinessContact(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.list(orgId)] });
      handleSuccessNotification('Business Information Updated successfully.');
      goNext();
    },
    onError: (err: Error | AxiosError) => {
      handleFailNotification(err);
      console.log(err);
    },
  });

  const formik = useFormik({
    initialValues: replaceNullWithEmptyString(orgDetails) || {
      business_contact_name: '',
      business_contact_phone: '',
      business_contact_dob: '',
      business_contact_email: '',
      business_contact_address_1: '',
      business_contact_address_2: '',
      business_contact_city: '',
      business_contact_state: '',
      business_contact_country_code: '',
      business_contact_title: '',
      business_contact_postal_code: '',
      business_contact_ssn: '',
      business_contact_dl: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      const payload: {
        [key: string]: any;
      } = {};
      const val: {
        [key: string]: any;
      } = { ...values };

      BUSINESS_INFO_FIELDS.forEach(elem => {
        payload[elem] = val[elem] == null ? '' : val[elem];
      });
      mutation.mutate({ ...payload });
    },
  });

  useEffect(() => {
    const fetchStates = async () => {
      const states = await getStates(formik.values.business_contact_country_code ?? '');
      setStateOptions(states);
    };
    fetchStates();
  }, [getStates, formik.values.business_contact_country_code]);

  const stateProps = {
    name: 'business_contact_state',
    value: formik.values.business_contact_state,
    onChange: formik.handleChange,
  };

  if (isOrgDetailsLoading) {
    return <Skeleton height="100%" width="60.75rem" />;
  }

  return (
    <Stack>
      <Text fontSize={'md'} fontWeight="medium">
        Business Contact Person
      </Text>
      <Text fontSize={'sm'} mb={2}>
        Key Business contact details.
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} gap={4}>
        <Field
          invalid={!!(formik.errors.business_contact_name && formik.touched.business_contact_name)}
          errorText={String(formik.errors.business_contact_name)}
          label="Name"
          required
        >
          <Input
            id="business_contact_name"
            type="business_contact_name"
            name="business_contact_name"
            value={formik.values.business_contact_name}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Email"
          invalid={!!(formik.errors.business_contact_email && formik.touched.business_contact_email)}
          errorText={String(formik.errors.business_contact_email)}
          required
        >
          <Input
            id="business_contact_email"
            type="email"
            name="business_contact_email"
            value={formik.values.business_contact_email}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Date of Birth"
          invalid={!!(formik.errors.business_contact_dob && formik.touched.business_contact_dob)}
          errorText={String(formik.errors.business_contact_dob)}
          required
        >
          <DatePicker
            selected={formik.values.business_contact_dob}
            onChange={date => {
              formik.setFieldValue('business_contact_dob', date);
            }}
          />
        </Field>

        <Field
          label="Title"
          invalid={!!(formik.errors.business_contact_title && formik.touched.business_contact_title)}
          errorText={String(formik.errors.business_contact_title)}
          required
        >
          <Input
            id="business_contact_title"
            type="text"
            name="business_contact_title"
            value={formik.values.business_contact_title}
            onChange={formik.handleChange}
          />
        </Field>

        <Field
          label="Phone Number"
          invalid={!!(formik.errors.business_contact_phone && formik.touched.business_contact_phone)}
          errorText={String(formik.errors.business_contact_phone)}
          required
        >
          <Input
            id="business_contact_phone"
            type="business_contact_phone"
            name="business_contact_phone"
            value={formik.values.business_contact_phone}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Country"
          invalid={!!(formik.errors.business_contact_country_code && formik.touched.business_contact_country_code)}
          errorText={String(formik.errors.business_contact_country_code)}
          required
        >
          <NativeSelectRoot disabled={isLoadingCountries}>
            <NativeSelectField
              name="business_contact_country_code"
              placeholder="Select Country"
              onChange={formik.handleChange}
              value={formik.values.business_contact_country_code}
            >
              {countries.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>

        <Field
          label="State"
          invalid={!!(formik.errors.business_contact_state && formik.touched.business_contact_state)}
          errorText={String(formik.errors.business_contact_state)}
          required
        >
          <NativeSelectRoot>
            <NativeSelectField placeholder="Select State" {...stateProps}>
              {stateOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>

        <Field
          label="City"
          invalid={!!(formik.errors.business_contact_city && formik.touched.business_contact_city)}
          errorText={String(formik.errors.business_contact_city)}
          required
        >
          <Input
            id="business_contact_city"
            type="business_contact_city"
            name="business_contact_city"
            value={formik.values.business_contact_city}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Postal Code"
          invalid={!!(formik.errors.business_contact_postal_code && formik.touched.business_contact_postal_code)}
          errorText={String(formik.errors.business_contact_postal_code)}
          required
        >
          <Input
            id="business_contact_postal_code"
            type="business_contact_postal_code"
            name="business_contact_postal_code"
            value={formik.values.business_contact_postal_code}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Address Line 1"
          invalid={!!(formik.errors.business_contact_address_1 && formik.touched.business_contact_address_1)}
          errorText={String(formik.errors.business_contact_address_1)}
          required
        >
          <Input
            id="business_contact_address_1"
            type="business_contact_address_1"
            name="business_contact_address_1"
            value={formik.values.business_contact_address_1}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Address Line 2"
          invalid={!!(formik.errors.business_contact_address_2 && formik.touched.business_contact_address_2)}
          errorText={String(formik.errors.business_contact_address_2)}
        >
          <Input
            id="business_contact_address_2"
            type="business_contact_address_2"
            name="business_contact_address_2"
            value={formik.values.business_contact_address_2}
            onChange={formik.handleChange}
          />
        </Field>

        <Field
          label={
            <Flex alignItems={'center'} gap="1">
              <Text>Social Security Number (SSN)</Text>
              <Tooltip content={`Enter NA if you do not have an SSN.`}>
                <Icon color="#4B5169" size="sm">
                  <MdInfo />
                </Icon>
              </Tooltip>
            </Flex>
          }
          invalid={!!(formik.errors.business_contact_ssn && formik.touched.business_contact_ssn)}
          errorText={String(formik.errors.business_contact_ssn)}
          required
        >
          <Input
            id="business_contact_ssn"
            type="business_contact_ssn"
            name="business_contact_ssn"
            value={formik.values.business_contact_ssn}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label={
            <Flex alignItems={'center'} gap="1">
              <Text>Drivers License (DLN)</Text>
              <Tooltip
                content={`A Driver's License Number (DLN) is a unique identifier on your driver's
license, issued by the state's licensing agency. Find it on the front
of your driver's license, typically listed near your photo or personal
information. Enter NA if you do not have one.`}
              >
                <Icon color="#4B5169" size="sm">
                  <MdInfo />
                </Icon>
              </Tooltip>
            </Flex>
          }
          invalid={!!(formik.errors.business_contact_dl && formik.touched.business_contact_dl)}
          errorText={String(formik.errors.business_contact_dl)}
          required
        >
          <Input
            id="business_contact_dl"
            type="business_contact_dl"
            name="business_contact_dl"
            value={formik.values.business_contact_dl}
            onChange={formik.handleChange}
          />
          {formik.errors.business_contact_dl && formik.touched.business_contact_dl && (
            <Text color={'#E53E3E'}>{String(formik.errors.business_contact_dl)}</Text>
          )}
        </Field>
      </SimpleGrid>
      <Flex justifyContent={'flex-end'}>
        <Button
          loading={mutation.isPending}
          disabled={!formik.isValid}
          width={'7rem'}
          alignSelf={'top'}
          onClick={() => {
            if (formik.isValid) {
              formik.handleSubmit();
            } else {
              formik.setTouched({
                business_contact_phone: true,
                business_contact_email: true,
                business_contact_address_1: true,
                business_contact_city: true,
                business_contact_state: true,
                business_contact_dob: true,
                business_contact_postal_code: true,
                business_contact_ssn: true,
                business_contact_dl: true,
              });
            }
          }}
        >
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default BusinessContactEdit;
