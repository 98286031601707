import { Flex, IconButton, Table, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getOrgInvoiceUsage } from 'apis/billing-apis';
import { ArrowLeft } from 'components/icons';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import {
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerRoot,
} from 'components/ui/drawer';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useOrg } from 'hooks/useOrg';
import { Invoice } from 'types/billings';
import { toDateShort } from 'utils/dates';
import { getHumanReadableString } from 'utils/enum-helpers';

interface InvoiceDetailsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  invoice: Invoice;
}

const TABLE_HEADERS = ['COUNTRY', 'JURISDICTION', 'REQUEST TYPE', 'COMPLETION DATE'];

export const InvoiceDetailsDrawer = ({ isOpen, onClose, invoice }: InvoiceDetailsDrawerProps) => {
  const { orgId } = useOrg();
  const invoiceId = invoice.id;

  const { data: usageData, isPending } = useQuery({
    queryKey: ['invoice-usage', orgId, invoiceId],
    queryFn: () => getOrgInvoiceUsage(orgId, invoiceId),
    enabled: isOpen && !!invoiceId,
  });

  return (
    <DrawerRoot open={isOpen} size="md">
      <DrawerBackdrop />
      <DrawerCloseTrigger onClick={onClose} />
      <DrawerContent>
        <DrawerHeader p={0}>
          {' '}
          <Flex align="center" p={4}>
            <IconButton aria-label="Back" variant="ghost" onClick={onClose} mr={2} size="sm">
              <ArrowLeft />
            </IconButton>
            <Text fontWeight={'medium'} fontSize={20}>
              Invoice Details
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {!usageData?.length && !isPending ? (
            <TableEmptyState tableName="Invoice Details" />
          ) : (
            <KDataTable
              headers={TABLE_HEADERS}
              defaultVisibleColumns={TABLE_HEADERS}
              showColumnFilter={false}
              isPending={isPending}
            >
              {usageData?.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{item.country}</Table.Cell>
                  <Table.Cell>{item.state}</Table.Cell>
                  <Table.Cell>{getHumanReadableString(item.request_type) ?? '-'}</Table.Cell>
                  <Table.Cell>{toDateShort(item.completion_date)}</Table.Cell>
                </Table.Row>
              ))}
              {isPending && <TableRowSkeleton length={25} columns={TABLE_HEADERS} />}
            </KDataTable>
          )}
        </DrawerBody>
      </DrawerContent>
    </DrawerRoot>
  );
};
