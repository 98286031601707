import { Stack } from '@chakra-ui/react';

import { PhysicalMail } from './physical-mail';
import { VirtualMail } from './virtual-email';

export const Communications = () => {
  return (
    <Stack gap={6}>
      <PhysicalMail />
      <VirtualMail />
    </Stack>
  );
};
