import { Box, BoxProps, Button, Flex } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type SecuredViewProps = PropsWithChildren<{
  isSecured: boolean;
  containerProps?: BoxProps;
  handlePreview?: () => void;
}>;
export const SecuredView = ({ children, isSecured, containerProps, handlePreview }: SecuredViewProps) => {
  if (!isSecured) {
    return children;
  }

  return (
    <Box position="relative" w="full" {...containerProps}>
      <Flex
        position="absolute"
        top="0"
        left="0"
        w="full"
        h="full"
        backdropFilter="blur(4px)"
        justify="center"
        align="center"
        zIndex={1}
        borderRadius={4}
      >
        {handlePreview && (
          <Button variant="outline" onClick={handlePreview}>
            Click to Preview
          </Button>
        )}
      </Flex>
      {children}
    </Box>
  );
};
