import { Badge, Box, Flex, HStack, Table, Text } from '@chakra-ui/react';
import { InfoIcon } from 'components/icons';
import { KDataTable } from 'components/table/data-table';
import { Tooltip } from 'components/ui/tooltip';
import { fromUnixTime } from 'date-fns';
import isArray from 'lodash/isArray';
import { Fragment } from 'react';
import { CreditNote, Invoice } from 'types/billings';
import { toDateShort } from 'utils/dates';
import { getHumanReadableString } from 'utils/enum-helpers';
import { formatCurrency } from 'utils/utils';

import { InvoiceAction } from './invoice-action';

type InvoicesTableProps = {
  invoices: Invoice[];
};

const statusBadges = {
  paid: <Badge colorPalette={'green'}>Paid</Badge>,
  not_paid: <Badge colorPalette={'red'}>Not Paid</Badge>,
  payment_due: <Badge colorPalette={'red'}>Payment Due</Badge>,
  pending: <Badge colorPalette={'gray'}>Pending</Badge>,
  voided: <Badge colorPalette={'red'}>Voided</Badge>,
} as Record<string, React.ReactNode>;

const getPlanId = (invoice: Invoice) => {
  if (invoice.line_items.length === 0) {
    return '-';
  }
  const planId = invoice.line_items[0].entity_id
    ? getHumanReadableString(invoice.line_items[0].entity_id.split('-')[1])
    : invoice.line_items[0].description;
  return planId;
};

const TABLE_HEADERS = ['Invoice Id', 'Plan', 'Billed On', 'Paid On', 'Amount', 'Tax', 'Total Amount', 'Status'];
const NUMERIC_COL = ['Amount', 'Tax', 'Total Amount'];

export const InvoicesTable = ({ invoices }: InvoicesTableProps) => {
  const tableRows =
    isArray(invoices) &&
    invoices.map(invoice => {
      const plan = getPlanId(invoice);
      return (
        <Table.Row key={invoice.id}>
          <Table.Cell>{invoice.id}</Table.Cell>
          <Table.Cell>{plan}</Table.Cell>
          <Table.Cell>{toDateShort(fromUnixTime(invoice.date))}</Table.Cell>
          <Table.Cell>{invoice.paid_at && toDateShort(fromUnixTime(invoice.paid_at))}</Table.Cell>
          <Table.Cell textAlign="end">{formatCurrency(invoice.sub_total / 100)}</Table.Cell>
          <Table.Cell textAlign="end">{formatCurrency(invoice.tax / 100)}</Table.Cell>
          <Table.Cell textAlign="end">
            <Flex justifyContent={'flex-end'} alignItems={'center'} gap={1}>
              {formatCurrency(invoice.total / 100)}
              {invoice.issued_credit_notes?.length > 0 && (
                <Tooltip
                  content={invoice.issued_credit_notes.map((creditNote: CreditNote, index: number) => (
                    <Fragment key={creditNote.id}>
                      <HStack p={'8px'} justifyContent={'space-between'}>
                        <Box>
                          <Text color={'white'} fontSize={'14px'}>
                            {creditNote.id}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={'white'}>Credit Issued</Text>
                        </Box>
                        <Box>
                          <Text color={'white'}>{getHumanReadableString(creditNote.status)}</Text>
                        </Box>
                        <Box>
                          <Text color={'white'}>{formatCurrency(creditNote.total)}</Text>
                        </Box>
                      </HStack>
                      {index < invoice.issued_credit_notes.length - 1 && (
                        <hr style={{ borderTop: '1px solid #383D58' }} />
                      )}
                    </Fragment>
                  ))}
                >
                  <InfoIcon height={18} width={18} />
                </Tooltip>
              )}
            </Flex>
          </Table.Cell>
          <Table.Cell width={{ sm: '80px' }}>
            {statusBadges[invoice.status] ?? <Badge colorPalette={'gray'}>{invoice.status}</Badge>}
          </Table.Cell>
          <Table.Cell width={'50px'} textAlign={'right'}>
            <InvoiceAction plan={plan} invoice={invoice} />
          </Table.Cell>
        </Table.Row>
      );
    });

  const emptyTableRow = (
    <Table.Row>
      <Table.Cell colSpan={8} textAlign={'center'}>
        Looks like there are no billing history here
      </Table.Cell>
    </Table.Row>
  );

  return (
    <KDataTable headers={TABLE_HEADERS} numericCols={NUMERIC_COL} showColumnFilter>
      {isArray(invoices) && invoices.length > 0 ? tableRows : emptyTableRow}
    </KDataTable>
  );
};
