import {
  Button,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteEmailGroupMember, getAllEmailGroups } from 'apis/organizations-apis';
import { usePaywall } from 'app/acl/paywall';
import { DeleteIcon } from 'components/icons';
import { Tooltip } from 'components/ui/tooltip';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { BillingPlanEnum } from 'schema/types-schema.d';

import { AddVirtualEmail } from './add-virtual-email';

const hideEmail = ['state-notifications@trykintsugi.com', 'state-notifications-dev@trykintsugi.com'];

export const VirtualMail = () => {
  const { orgId } = useOrg();
  const { open, onOpen, onClose } = useDisclosure();
  const { billingPlan } = usePaywall();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const { data: virtualMail, isLoading } = useQuery({
    queryKey: ['VIRTUAL-EMAIL', orgId],
    queryFn: async () => {
      const res = await getAllEmailGroups(orgId);
      return res?.data;
    },
    enabled: billingPlan != BillingPlanEnum.FREE,
  });

  const mutation = useMutation({
    mutationFn: ({ member, emailGroup }: { member: string; emailGroup: string }) => {
      return deleteEmailGroupMember(orgId, emailGroup, member);
    },
    onSuccess: () => {
      handleSuccessNotification('Email ID successfully removed.');
      queryClient.invalidateQueries({ queryKey: ['VIRTUAL-EMAIL'] });
    },
    onError: (error: any) => {
      handleFailNotification(error);
    },
  });

  const handleDeleteMember = (member: string) => {
    if (virtualMail?.[0]) {
      mutation.mutate({ member, emailGroup: virtualMail[0] });
    }
  };

  if (isLoading) {
    return <Skeleton height="46.5rem" width="full" />;
  }

  const isAddEmailDisabled = virtualMail?.[1]?.length >= 10;

  return (
    <Stack w="100%" border="1px solid #ddd" p={4} borderRadius={4} height={'766px'}>
      <HStack justify={'space-between'}>
        <Stack flexDirection={'column'}>
          <Text fontSize="md" fontWeight="medium">
            Virtual Mail Group
          </Text>
          {virtualMail && <Text fontSize="sm">Group ID: {virtualMail[0]}</Text>}
        </Stack>
        <Stack align={'center'}>
          <Tooltip
            content={'A maximum of 10 emails can be added. Please remove an existing one to add a new one.'}
            positioning={{ placement: 'bottom-start' }}
            disabled={!isAddEmailDisabled}
          >
            <Button
              fontSize={'sm'}
              width={'120px'}
              fontWeight={'500'}
              onClick={onOpen}
              disabled={isAddEmailDisabled}
              hidden={!virtualMail}
            >
              Add Email
            </Button>
          </Tooltip>
        </Stack>
        {open && virtualMail && <AddVirtualEmail isOpen={open} onClose={onClose} emailGroup={virtualMail[0]} />}
      </HStack>

      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeader fontSize={'sm'} pl={'8px'}>
              Email
            </Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {virtualMail?.[1]?.length > 0 ? (
            virtualMail[1]
              .filter((email: string) => !hideEmail.includes(email))
              .map((email: string, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell fontSize={'14px'} padding={'17px 0px 17px 8px'}>
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                      {email}
                      <Tooltip content="Remove">
                        <IconButton
                          aria-label="delete-member"
                          variant={'transparent-with-icon'}
                          onClick={() => handleDeleteMember(email)}
                        >
                          {mutation.isPending ? <Spinner /> : <DeleteIcon size="lg" />}
                        </IconButton>
                      </Tooltip>
                    </Flex>
                  </Table.Cell>
                </Table.Row>
              ))
          ) : (
            <Table.Row>
              <Table.Cell fontSize={'14px'} padding={'17px 0px 17px 8px'}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  No emails available
                </Flex>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </Stack>
  );
};
