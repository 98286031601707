import { CheckboxCheckedChangeDetails, DialogBackdrop, DialogHeader, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ORGANIZATION_SERVICE_QUERY_KEYS,
  putAutoFileAutoRegister,
  useAutoFileAutoRegisterQuery,
} from 'apis/organizations-apis';
import { usePaywall } from 'app/acl/paywall';
import { Button } from 'components/ui/button';
import { DialogBody, DialogContent, DialogFooter, DialogRoot, DialogTitle } from 'components/ui/dialog';
import { Switch } from 'components/ui/switch';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';

export const AutoFile = () => {
  const { orgId } = useOrg();
  const { isPaidUser } = usePaywall();
  const [, setSearchParams] = useQueryParam('');
  const { handleFailNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const { isPending, data: orgDetails } = useAutoFileAutoRegisterQuery(orgId);

  const { open, onOpen, onClose } = useDisclosure();

  const handleToggle = (event: CheckboxCheckedChangeDetails) => {
    const checked = event.checked;
    if (!isPaidUser) {
      setSearchParams({ openPricingModal: 'true' });
    } else if (!checked && isPaidUser) {
      onOpen();
    } else {
      formik.setFieldValue('auto_file', true);
      formik.handleSubmit();
    }
  };

  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return putAutoFileAutoRegister(orgId, payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.autoFileAutoRegister(orgId)] });
    },
    onError: (error: any) => {
      handleFailNotification(error);
    },
  });

  const formik = useFormik({
    initialValues: {
      auto_file: orgDetails?.auto_file || false,
      auto_register: orgDetails?.auto_register || false,
    },
    onSubmit: values => {
      mutation.mutate(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (orgDetails) {
      formik.setValues({
        auto_register: orgDetails.auto_register || false,
        auto_file: orgDetails.auto_file || false,
      });
    }
  }, [orgDetails]);

  const handleSave = () => {
    formik.setFieldValue('auto_file', false);
    formik.handleSubmit();
    onClose();
  };
  if (isPending) return null;
  return (
    <>
      <Switch size="lg" checked={formik.values.auto_file} onCheckedChange={handleToggle} />
      <DialogRoot
        open={open}
        onOpenChange={({ open }) => {
          if (!open) {
            onClose();
          }
        }}
        size={'xl'}
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure you want to turn OFF Auto File?</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <Text>
              Turning Auto File ON helps you file before the due date and avoid late fees. You have a few days to check
              them before they are auto-approved. Please confirm if you still want to proceed.
            </Text>
          </DialogBody>
          <DialogFooter>
            <Button w="60px" color={'secondary'} variant={'outline'} mr={4} onClick={onClose}>
              Cancel
            </Button>
            <Button w="90px" variant="solid" type="submit" onClick={handleSave}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};
