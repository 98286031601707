import { createListCollection, IconButton, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API_KEYS_STATE, createAPIKey } from 'apis/organizations-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { Tooltip } from 'components/ui/tooltip';
import { addMonths } from 'date-fns/addMonths';
import { addYears } from 'date-fns/addYears';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { ApiKeyCreateResponse, APIKeyRequest } from 'types/organizations';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  expires_at_seconds: Yup.number().required('Expiration time is required'),
});

const EXPIRES_OPTIONS = [
  { label: 'Never', value: -1 },
  { label: 'One Month', value: Math.floor(addMonths(new Date(), 1).getTime() / 1000) },
  { label: 'Six Month', value: Math.floor(addMonths(new Date(), 6).getTime() / 1000) },
  { label: 'One Year', value: Math.floor(addYears(new Date(), 1).getTime() / 1000) },
];

export const APIKeyModal = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [createdAPIKey, setCreatedAPIKey] = useState<ApiKeyCreateResponse | undefined>();
  const [showFullApiKey, setShowFullApiKey] = useState(false);
  const isOpen = searchParams.get('createApiKey') === 'true';

  const { mutate, isPending: createAPIKeyIsPending } = useMutation<ApiKeyCreateResponse, unknown, APIKeyRequest>({
    mutationFn: async req => {
      const { data } = await createAPIKey(orgId, req);
      return data;
    },
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [API_KEYS_STATE] });
      handleSuccessNotification('API Key successfully added.');
      setCreatedAPIKey(data);
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const {
    setFieldValue,
    resetForm,
    isValid,
    errors,
    values: { expires_at_seconds },
    handleSubmit,
  } = useFormik({
    initialValues: {
      expires_at_seconds: undefined,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: values =>
      mutate({ expires_at_seconds: values.expires_at_seconds === -1 ? undefined : values.expires_at_seconds }),
  });

  const expireCollection = useMemo(() => {
    return createListCollection({
      items: EXPIRES_OPTIONS,
    });
  }, [EXPIRES_OPTIONS]);

  const toggleAPIKeyModal = useCallback(() => {
    setSearchParams(params => {
      if (params.has('createApiKey')) {
        params.delete('createApiKey');
      } else {
        params.set('createApiKey', 'true');
      }
      return params;
    });
  }, [setSearchParams]);

  useEffect(() => {
    resetForm();
    setShowFullApiKey(false);
    setCreatedAPIKey(undefined);
  }, [isOpen]);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          toggleAPIKeyModal();
        }
      }}
    >
      <DialogTrigger asChild>
        <Button w="6.25rem" onClick={toggleAPIKeyModal}>
          New
        </Button>
      </DialogTrigger>
      <DialogBackdrop />
      <DialogContent ref={contentRef} colorPalette="blue">
        <DialogHeader>
          <DialogTitle>New Organization API Key</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody pb={6}>
          {!createdAPIKey && (
            <Field
              label="When should this API key expire"
              invalid={!!errors.expires_at_seconds}
              errorText={errors.expires_at_seconds}
              required
            >
              <SelectRoot
                collection={expireCollection}
                value={expires_at_seconds ? [expires_at_seconds] : undefined}
                onValueChange={({ value }) => {
                  setFieldValue('expires_at_seconds', value[0]);
                }}
              >
                <SelectTrigger>
                  <SelectValueText />
                </SelectTrigger>
                <SelectContent portalRef={contentRef}>
                  {expireCollection.items.map(item => (
                    <SelectItem key={item.value} item={item}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectRoot>
            </Field>
          )}
          {!!createdAPIKey && (
            <>
              <Text mb={4}>
                Your API key has been successfully created. Be sure to copy it now, you will not be able to view it
                again.
              </Text>
              <Text fontWeight="medium" mb={1}>
                API Key
              </Text>
              {!showFullApiKey && (
                <Text>
                  {createdAPIKey.api_key_token.substring(0, 41)}...
                  <Tooltip content="Copy">
                    <IconButton
                      aria-label={'Copy'}
                      onClick={() => {
                        if (navigator.clipboard) {
                          navigator.clipboard
                            .writeText(createdAPIKey.api_key_token)
                            .then(() => {
                              handleSuccessNotification('API key copied to clipboard');
                            })
                            .catch(err => {
                              console.error('Failed to copy API key:', err);
                              handleFailNotification('Failed to copy API key. Please try again.');
                            });
                        } else {
                          handleFailNotification('Clipboard access not available. Please copy the key manually.');
                        }
                      }}
                    >
                      <MdContentCopy />
                    </IconButton>
                  </Tooltip>
                </Text>
              )}
              {showFullApiKey && <Text>{createdAPIKey.api_key_token}</Text>}
              {!showFullApiKey && (
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    setShowFullApiKey(true);
                  }}
                >
                  Manually copy API key
                </Button>
              )}
            </>
          )}
        </DialogBody>

        <DialogFooter>
          {!createdAPIKey && (
            <>
              <Button
                disabled={createAPIKeyIsPending}
                variant="outline"
                color="secondary"
                borderColor="secondary"
                onClick={toggleAPIKeyModal}
              >
                Cancel
              </Button>
              <Button
                loading={createAPIKeyIsPending}
                disabled={!isValid || createAPIKeyIsPending}
                w="6.25rem"
                variant="solid"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </>
          )}
          {!!createdAPIKey && (
            <Button
              w="6.25rem"
              variant="solid"
              onClick={() => {
                setCreatedAPIKey(undefined);
                toggleAPIKeyModal();
              }}
            >
              Done
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
