import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UsersRequest } from 'types/organizations';

type UseUsersFilterReturn = {
  pageSize: number;
  pageNumber: number;
  includeOrgs: boolean | undefined;
  role: string | undefined;
  setFilters: (filters: UsersRequest) => void;
};

export const useUsersFilter = (): UseUsersFilterReturn => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Page size
  const pageSize = searchParams.get('pageSize');
  const pageSizeInNumber = pageSize ? parseInt(pageSize) : 100;
  const validPageSize = isNaN(pageSizeInNumber) ? 100 : pageSizeInNumber;

  // Page number
  const pageNumber = searchParams.get('pageNumber');
  const pageNumberInNumber = pageNumber ? parseInt(pageNumber) : 0;
  const validPageNumber = isNaN(pageNumberInNumber) ? 0 : pageNumberInNumber;

  const includeOrgs = searchParams.get('includeOrgs') ? searchParams.get('includeOrgs') === 'true' : undefined;
  const role = searchParams.get('role') ?? undefined;

  const setFilters = useCallback((filters: UsersRequest) => {
    setSearchParams(params => {
      params.set('pageSize', filters.pageSize.toString());
      params.set('pageNumber', filters.pageNumber.toString());
      if (filters.includeOrgs) {
        params.set('includeOrgs', filters.includeOrgs.toString());
      } else {
        params.delete('includeOrgs');
      }
      if (filters.role) {
        params.set('role', filters.role);
      } else {
        params.delete('role');
      }
      return params;
    });
  }, []);

  return {
    pageSize: validPageSize,
    pageNumber: validPageNumber,
    includeOrgs,
    role,
    setFilters,
  };
};
