import { IconButton } from '@chakra-ui/react';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { MdMoreVert } from 'react-icons/md';

type APIKeyMenuProps = { id: string; handleDeleteApiKey: (id: string) => void };
export const APIKeyMenu = ({ id, handleDeleteApiKey }: APIKeyMenuProps) => (
  <MenuRoot
    onSelect={({ value }) => {
      if (value === 'delete') {
        handleDeleteApiKey(id);
      }
    }}
  >
    <MenuTrigger asChild>
      <IconButton variant="transparent-with-icon" aria-label="Options" h="6" w="6" borderRadius={4}>
        <MdMoreVert size="xl" />
      </IconButton>
    </MenuTrigger>
    <MenuContent>
      <MenuItem value="delete" color="red.500" aria-label={`Delete API Key ${id.slice(0, 8)}...`}>
        Delete API Key
      </MenuItem>
    </MenuContent>
  </MenuRoot>
);
