import { useDisclosure } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import useQueryParam from 'hooks/useQueryParam';
import { BillingDetail } from 'types/billings';
import { Maybe } from 'types/utils';

import { UpgradeBillingPlanFromGrowthModal } from './upgrade-billing-plan-from-growth-modal';

type UpgradeBillingPlanProps = {
  billingDetail: Maybe<BillingDetail>;
  onUpgradeSuccess: () => void;
};
export const UpgradeBillingPlan = ({ billingDetail, onUpgradeSuccess }: UpgradeBillingPlanProps) => {
  const { open, onClose, onOpen } = useDisclosure();
  const [, setSearchParams] = useQueryParam('');

  if (billingDetail && billingDetail.billing_plan === 'PREMIUM') {
    return null;
  }

  const canUpgrade = ['FREE', 'GROWTH'].includes(billingDetail?.billing_plan ?? '');

  const showUpgradeFromFreeModal = billingDetail && billingDetail.billing_plan === 'FREE';
  const showUpgradeFromGrowthModal = billingDetail && billingDetail.billing_plan === 'GROWTH' && open;

  const handleClick = () => {
    if (showUpgradeFromFreeModal) {
      setSearchParams({ openPricingModal: 'true' });
    } else {
      onOpen();
    }
  };

  return (
    <>
      <Button disabled={!canUpgrade} onClick={handleClick} fontSize={'sm'} w={'100px'} fontWeight={'500'}>
        Upgrade
      </Button>

      {showUpgradeFromGrowthModal && (
        <UpgradeBillingPlanFromGrowthModal isOpen={open} onClose={onClose} onUpgradeSuccess={onUpgradeSuccess} />
      )}
    </>
  );
};
