import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { APIKeysRequest } from 'types/organizations';

type UseApiKeysFilterReturn = {
  pageSize: number;
  pageNumber: number;
  active: boolean | undefined;
  setFilters: (filters: APIKeysRequest) => void;
};

export const useApiKeysFilter = (): UseApiKeysFilterReturn => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Page size
  const pageSize = searchParams.get('pageSize');
  const pageSizeInNumber = pageSize ? parseInt(pageSize) : 25;
  const validPageSize = Number.isNaN(pageSizeInNumber) ? 25 : pageSizeInNumber;

  // Page number
  const pageNumber = searchParams.get('pageNumber');
  const pageNumberInNumber = pageNumber ? parseInt(pageNumber) : 0;
  const validPageNumber = Number.isNaN(pageNumberInNumber) ? 0 : pageNumberInNumber;
  const active = searchParams.get('active') ? searchParams.get('active') === 'true' : undefined;

  const setFilters = useCallback((filters: APIKeysRequest) => {
    setSearchParams(params => {
      params.set('pageSize', filters.pageSize.toString());
      params.set('pageNumber', filters.pageNumber.toString());
      if (filters.active) {
        params.set('active', filters.active.toString());
      } else {
        params.delete('active');
      }
      return params;
    });
  }, []);

  return {
    pageSize: validPageSize,
    pageNumber: validPageNumber,
    active,
    setFilters,
  };
};
