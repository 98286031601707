import { IconButton, useDisclosure } from '@chakra-ui/react';
import { getOrgInvoicePdfFromChargebee } from 'apis/billing-apis';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { MdMoreVert } from 'react-icons/md';
import { Invoice } from 'types/billings';

import { InvoiceDetailsDrawer } from './invoice-details-drawer';

type InvoiceActionProps = {
  invoice: Invoice;
  plan: string;
};

export const InvoiceAction = ({ invoice, plan }: InvoiceActionProps) => {
  const { orgId } = useOrg();

  const { open, onOpen, onClose } = useDisclosure();
  const { handleFailNotification } = useHandleNotification();

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleDownload = (invoiceId: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (invoiceId) {
      getOrgInvoicePdfFromChargebee(orgId, invoiceId)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `invoice_${invoiceId}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          handleFailNotification(error);
        });
    }
  };

  return (
    <>
      <MenuRoot positioning={{ placement: 'bottom-start' }}>
        <MenuTrigger asChild onClick={handleClick}>
          <IconButton aria-label="Options" variant="transparent-with-icon">
            <MdMoreVert />
          </IconButton>
        </MenuTrigger>
        <MenuContent>
          {plan !== 'Premium' && (
            <MenuItem value="details" onClick={onOpen}>
              View Details
            </MenuItem>
          )}
          <MenuItem value="download" onClick={() => handleDownload(invoice.id)}>
            Download Invoice
          </MenuItem>
        </MenuContent>
      </MenuRoot>
      <InvoiceDetailsDrawer isOpen={open} onClose={onClose} invoice={invoice} />
    </>
  );
};
