import { Flex, Input, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addEmailGroupMember } from 'apis/organizations-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { object, string } from 'yup';

const validationSchema = object().shape({
  member: string().email('Invalid email address').required('Email is required'),
});

type AddVirtualEmail = {
  isOpen: boolean;
  onClose: () => void;
  emailGroup: string;
};

export const AddVirtualEmail = ({ isOpen, onClose, emailGroup }: AddVirtualEmail) => {
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return addEmailGroupMember(orgId, payload);
    },
    onSuccess: () => {
      handleSuccessNotification('Email ID successfully added.');
      queryClient.invalidateQueries({ queryKey: ['VIRTUAL-EMAIL', orgId] });
      onClose();
    },
    onError: (error: any) => {
      handleFailNotification(error);
      onClose();
    },
  });

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      member: '',
      email_group: emailGroup,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutation.mutate(values);
    },
  });

  return (
    <DialogRoot closeOnInteractOutside={false} size={'md'} open={isOpen}>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger onClick={onClose} />
        <DialogHeader>
          <DialogTitle>Add Email ID to the Virtual Mail Group</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Stack gap={4}>
            <Text>Add an email ID to the mailing group to receive notifications from the tax jurisdictions</Text>
            <Field
              label="Email"
              invalid={!!(errors.member && touched.member)}
              errorText={String(errors.member)}
              required
            >
              <Input id="member" type="member" name="member" value={values.member} onChange={handleChange} />
            </Field>
          </Stack>
        </DialogBody>
        <DialogFooter>
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button variant={'solid'} loading={mutation.isPending} onClick={() => handleSubmit()}>
              Confirm
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
