import { Box, Flex, Grid, GridItem, SimpleGrid, Skeleton, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BILLING_STATE_KEY,
  generateChargebeePortalSession,
  getOrgBillingInfo,
  getOrgBillingMatrix,
  getOrgInvoicesFromChargebee,
} from 'apis/billing-apis';
import { Button } from 'components/ui/button';
import { useOrg } from 'hooks/useOrg';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { BillingPlanEnum } from 'schema/types-schema.d';
import { BillingDetail, BillingMatrix } from 'types/billings';
import { toNormalCase } from 'utils';
import { chargebee } from 'utils/chargebee';
import { formatCurrency } from 'utils/utils';

import { BillingHistoryChart } from './billing-history/chart';
import { InvoicesTable } from './billing-history/invoices-table';
import { UpgradeBillingPlan } from './billing-plans/upgrade-billing-plan';

const buildAddress = (billingDetail: BillingDetail) => {
  if (!billingDetail?.customer?.billing_address) {
    return '';
  }

  const addressLine1 = billingDetail.customer.billing_address.line1 || '';
  const addressLine2 = billingDetail.customer.billing_address.line2 || '';
  const city = billingDetail.customer.billing_address.city || '';
  const state = billingDetail.customer.billing_address.state || '';
  const zip = billingDetail.customer.billing_address.zip || '';
  const country = billingDetail.customer.billing_address.country || '';

  // Build the address string with proper separators
  const addressParts = [addressLine1, addressLine2, city, state, zip, country];
  return addressParts.filter(part => part.length > 0).join(', ');
};

const BillingDetails = () => {
  const { orgId, isTest } = useOrg();
  const queryClient = useQueryClient();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { data: billingDetail, isPending } = useQuery({
    queryKey: [BILLING_STATE_KEY, 'details', orgId],
    queryFn: () => getOrgBillingInfo(orgId),
    select: (data): BillingDetail => data || { billing_plan: 'FREE' },
  });

  const isPaidPlan = billingDetail?.billing_plan !== BillingPlanEnum.FREE;
  const isGrowthPlan = billingDetail?.billing_plan === BillingPlanEnum.GROWTH;

  const { data: billingMatrix, isLoading: isBillingMatrixLoading } = useQuery({
    queryKey: [BILLING_STATE_KEY, 'billing', 'matrix', orgId],
    queryFn: async () => {
      const data = await getOrgBillingMatrix(orgId);
      return data satisfies BillingMatrix;
    },
    enabled: isGrowthPlan,
  });

  const { data: invoices = [], isLoading: isInvoiceLoading } = useQuery({
    queryKey: [BILLING_STATE_KEY, 'invoices', orgId],
    queryFn: async () => await getOrgInvoicesFromChargebee(orgId),
    enabled: isPaidPlan,
  });

  const handleUpgradeSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [BILLING_STATE_KEY, 'details', orgId],
    });
    queryClient.invalidateQueries({
      queryKey: [BILLING_STATE_KEY, 'invoices', orgId],
    });
  };

  if (isPending || isInvoiceLoading || isBillingMatrixLoading) {
    return (
      <Grid templateRows="repeat(2, 1fr) 6fr" templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={2}>
          <Skeleton h={'116px'} w={'full'} />
        </GridItem>
        <GridItem colSpan={2}>
          <Skeleton h={'116px'} w={'full'} />
        </GridItem>
        <GridItem colSpan={2}>
          <Skeleton h={'116px'} w={'full'} />
        </GridItem>
        <GridItem colSpan={2}>
          <Skeleton h={'116px'} w={'full'} />
        </GridItem>
        <GridItem colSpan={4}>
          <Skeleton h={'full'} w={'full'} />
        </GridItem>
      </Grid>
    );
  }

  const handleAddOrUpdate = async (sectionType: string) => {
    const chargebeeInstance = await chargebee(isTest);
    chargebeeInstance?.setPortalSession(() => generateChargebeePortalSession(orgId));
    const portal = chargebeeInstance?.createChargebeePortal();
    let options = {
      sectionType,
    } as Record<string, any>;
    if (billingDetail?.subscription_id) {
      options = {
        ...options,
        params: {
          subscriptionId: billingDetail.subscription_id,
        },
      };
    }
    portal?.openSection(options, {
      close: () => {
        handleUpgradeSuccess();
        chargebeeInstance?.closeAll();
      },
    });
  };
  const debouncedHandleAddOrUpdate = debounce(handleAddOrUpdate, 500);

  const formatUnitPrice = (unitPrice: number) => `${formatCurrency((unitPrice ?? 0) / 100)}`;

  const getBillingPlanDescription = (billingDetail?: BillingDetail): string => {
    if (!billingDetail) return '$0 as a base price, no additional cost';

    const { billing_plan, subscription } = billingDetail;
    const { billing_period_unit, billing_period, subscription_items } = subscription || {};
    const unitPrice = subscription_items?.[0]?.unit_price;

    const getPeriodDescription = () => {
      switch (billing_period_unit) {
        case 'month':
          return 'Month';
        case 'year':
          switch (billing_period) {
            case 2:
              return 'Bi-Yearly';
            case 3:
              return 'Tri-Yearly';
            default:
              return 'Year';
          }
      }
    };

    const calculateDiscount = () => {
      if (subscription?.discount_type === 'percentage') {
        const discount = (unitPrice * (subscription.discount_percentage ?? 0)) / 100;
        return formatUnitPrice(unitPrice - discount);
      }
      if (subscription?.discount_type === 'fixed_amount') {
        return formatUnitPrice(unitPrice - (subscription.discount_amount ?? 0));
      }
      return formatUnitPrice(unitPrice);
    };

    const periodDescription = getPeriodDescription();

    switch (billing_plan) {
      case 'PREMIUM':
        return `${calculateDiscount()}/${periodDescription}`;

      case 'GROWTH':
        return `$0 as a base price, Cost ${calculateDiscount()}/State`;
      default:
        return '$0 as a base price, no additional cost';
    }
  };

  return (
    <Stack>
      <Text fontSize={'md'} fontWeight="500">
        Plan Overview
      </Text>
      <Grid templateColumns={isMobile ? '1fr' : 'repeat(2, 1fr)'} gap={4}>
        <GridItem border={'1px #ddd solid'} borderRadius={4} p={4}>
          <Flex justify={'space-between'}>
            <Box>
              <Text fontSize={'md'}>Current Subscription Plan</Text>
              <Text mt={2}>
                <Text fontWeight="semibold" as="span">
                  {toNormalCase(billingDetail?.billing_plan ?? '')}
                </Text>
              </Text>
              <Text fontSize={'sm'}>{getBillingPlanDescription(billingDetail)}</Text>
              {billingDetail?.subscription?.coupon && <Text>{billingDetail?.subscription?.coupon} applied</Text>}
            </Box>
            <UpgradeBillingPlan billingDetail={billingDetail} onUpgradeSuccess={handleUpgradeSuccess} />
          </Flex>
        </GridItem>
        <GridItem border={'1px #ddd solid'} borderRadius={4} p={4}>
          <Flex justify={'space-between'}>
            <Box>
              <Text fontSize={'md'} mb={2}>
                Payment Method
              </Text>
              {isEmpty(billingDetail?.card) ? (
                <Text>
                  <Text as="span" fontWeight="semibold">
                    No payment method added
                  </Text>
                  <br />
                  You will see the payment method details here once you upgrade.
                </Text>
              ) : (
                <>
                  <Text>
                    <Text fontWeight="semibold" as="span">
                      {billingDetail?.card.masked_number ?? ''}
                    </Text>
                  </Text>
                  <Text fontSize={'sm'}>
                    Expires {billingDetail?.card.expiry_month}/{billingDetail?.card.expiry_year}
                  </Text>
                </>
              )}
            </Box>
            {!isEmpty(billingDetail?.card) && (
              <Button
                onClick={() => debouncedHandleAddOrUpdate('portal_payment_methods')}
                fontSize={'sm'}
                w={'100px'}
                fontWeight={'500'}
                variant={'outline'}
                color={'secondary'}
              >
                Edit
              </Button>
            )}
          </Flex>
        </GridItem>
        <GridItem border={'1px #ddd solid'} borderRadius={4} p={4}>
          <Flex justify={'space-between'}>
            <Box>
              <Text fontSize={'md'} mb={2}>
                Billing Contact
              </Text>
              {isEmpty(billingDetail?.customer?.billing_address) ? (
                <Text mb={4}>
                  <Text as="span" fontWeight="semibold">
                    No billing contact added
                  </Text>
                  <br />
                  You will see the billing contact details here once you upgrade.
                </Text>
              ) : (
                <>
                  <Text>
                    <Text fontWeight="semibold" as="span">
                      {`${billingDetail?.customer.billing_address.first_name} ${billingDetail?.customer.billing_address.last_name}`}
                    </Text>
                  </Text>
                  <Text fontSize={'sm'}>{buildAddress(billingDetail)}</Text>
                  <Text fontSize={'sm'}>Email: {billingDetail?.customer.email}</Text>
                </>
              )}
            </Box>
            {!isEmpty(billingDetail?.customer?.billing_address) && (
              <Button
                onClick={() => debouncedHandleAddOrUpdate('portal_address')}
                variant={'outline'}
                color={'secondary'}
                fontSize={'sm'}
                w={'100px'}
                fontWeight={'500'}
              >
                Edit
              </Button>
            )}
          </Flex>
        </GridItem>
        {!isEmpty(billingDetail?.subscription?.subscription_items) && (
          <GridItem border={'1px #ddd solid'} borderRadius={4} p={4}>
            <Flex justify={'space-between'}>
              <Box>
                <Text fontSize={'md'} mb={2}>
                  Subscription Items
                </Text>
                <SimpleGrid columns={2} gap={8}>
                  <Box>
                    <Text fontSize="sm">Plan: {toNormalCase(billingDetail?.billing_plan ?? '')}</Text>
                    <Text fontSize="sm">Subscription Details: {toNormalCase(billingDetail?.billing_plan ?? '')}</Text>
                    <Text fontSize="sm">
                      Unit Price:{' '}
                      {formatCurrency((billingDetail?.subscription?.subscription_items[0]?.unit_price ?? 0) / 100)}
                    </Text>
                  </Box>
                  {isGrowthPlan && (
                    <Box>
                      <Text fontSize="sm">States: {billingMatrix?.used_states ?? 0}</Text>
                      <Text fontSize="sm">Estimate: {formatCurrency((billingMatrix?.used_states ?? 0) * 100)}</Text>
                    </Box>
                  )}
                </SimpleGrid>
              </Box>
            </Flex>
          </GridItem>
        )}
      </Grid>
      {billingDetail?.billing_plan !== 'FREE' && (
        <Flex gap={4} flexDir={'column'} mt={2}>
          <Text fontWeight={'500'} fontSize={'md'}>
            Billing History
          </Text>
          <BillingHistoryChart />
          <InvoicesTable invoices={invoices} />
        </Flex>
      )}
    </Stack>
  );
};

export default BillingDetails;
