import { BillingPlanEnum } from 'schema/types-schema.d';

export enum CreditNoteStatus {
  ADJUSTED = 'adjusted',
  REFUNDED = 'refunded',
  REFUND_DUE = 'refund_due',
  VOIDED = 'voided',
}

type SubscriptionItems = {
  item_price_id: string;
  item_type: string;
  quantity: number;
  unit_price: number;
  amount: number;
  free_quantity: number;
  object: string;
};

type Address = {
  first_name: string;
  last_name: string;
  line1: string;
  city: string;
  state_code: string;
  state: string;
  country: string;
  validation_status: string;
  object: string;
};

type Subscription = {
  id: string;
  billing_period: number;
  billing_period_unit: string;
  customer_id: string;
  status: string;
  current_term_start: number;
  current_term_end: number;
  next_billing_at: number;
  created_at: number;
  started_at: number;
  activated_at: number;
  created_from_ip: string;
  updated_at: number;
  has_scheduled_changes: boolean;
  channel: string;
  resource_version: number;
  deleted: boolean;
  object: string;
  currency_code: string;
  coupon?: string;
  subscription_items: SubscriptionItems[];
  shipping_address?: Address;
  due_invoices_count: number;
  mrr: number;
  exchange_rate: number;
  base_currency_code: string;
  cf_orgId: string;
  has_scheduled_advance_invoices: boolean;
  create_pending_invoices: boolean;
  auto_close_invoices: boolean;
  discount_type?: 'fixed_amount' | 'percentage';
  discount_percentage?: number;
  discount_amount?: number;
};

type Customer = {
  id: string;
  email: string;
  auto_collection: string;
  net_term_days: number;
  allow_direct_debit: boolean;
  created_at: number;
  created_from_ip: string;
  taxability: string;
  updated_at: number;
  pii_cleared: string;
  channel: string;
  resource_version: number;
  deleted: boolean;
  object: string;
  billing_address: {
    first_name: string;
    last_name: string;
    line1: string;
    line2: string;
    city: string;
    state_code: string;
    state: string;
    country: string;
    validation_status: string;
    object: string;
    zip: string;
  };
  card_status: string;
  promotional_credits: number;
  refundable_credits: number;
  excess_payments: number;
  unbilled_charges: number;
  preferred_currency_code: string;
  mrr: number;
  primary_payment_source_id: string;
  payment_method: {
    object: string;
    type: string;
    reference_id: string;
    gateway: string;
    gateway_account_id: string;
    status: string;
  };
  auto_close_invoices: boolean;
};

type Card = {
  status: string;
  gateway: string;
  gateway_account_id: string;
  iin: string;
  last4: string;
  card_type: string;
  funding_type: string;
  expiry_month: number;
  expiry_year: number;
  created_at: number;
  updated_at: number;
  ip_address: string;
  resource_version: number;
  object: string;
  masked_number: string;
  customer_id: string;
  payment_source_id: string;
};

export type CreditNote = {
  id: string;
  status: CreditNoteStatus;
  total: string;
};

export type Invoice = {
  id: string;
  customer_id: string;
  subscription_id: string;
  recurring: boolean;
  status: string;
  price_type: string;
  date: number;
  due_date: number;
  net_term_days: number;
  exchange_rate: number;
  total: number;
  amount_paid: number;
  amount_adjusted: number;
  write_off_amount: number;
  credits_applied: number;
  amount_due: number;
  paid_at: number | null;
  dunning_status: string;
  updated_at: number;
  resource_version: number;
  deleted: boolean;
  object: string;
  first_invoice: boolean;
  amount_to_collect: number;
  round_off_amount: number;
  has_advance_charges: boolean;
  currency_code: string;
  base_currency_code: string;
  generated_at: number;
  is_gifted: boolean;
  term_finalized: boolean;
  channel: string;
  tax: number;
  line_items: LineItem[];
  taxes: Tax[];
  line_item_taxes: LineItemTax[];
  sub_total: number;
  linked_payments: LinkedPayment[];
  applied_credits: any[];
  adjustment_credit_notes: any[];
  issued_credit_notes: CreditNote[];
  linked_orders: any[];
  dunning_attempts: DunningAttempt[];
  billing_address: IngAddress;
  shipping_address: IngAddress;
};

export type InvoiceUsage = {
  country: string;
  state: string;
  request_type: string;
  completion_date: string;
};

type IngAddress = {
  first_name: string;
  last_name: string;
  line1: string;
  city: string;
  state_code: string;
  state: string;
  country: string;
  zip: string;
  validation_status: string;
  object: string;
};

type DunningAttempt = {
  created_at: number;
  attempt: number;
  dunning_type: string;
  transaction_id: string;
  txn_status: string;
  txn_amount: number;
};

type LineItemTax = {
  tax_name: string;
  tax_rate: number;
  tax_juris_type: string;
  tax_juris_name: string;
  tax_juris_code: string;
  object: string;
  line_item_id: string;
  tax_amount: number;
  is_partial_tax_applied: boolean;
  taxable_amount: number;
  is_non_compliance_tax: boolean;
};

type LineItem = {
  id: string;
  date_from: number;
  date_to: number;
  unit_amount: number;
  quantity: number;
  amount: number;
  pricing_model: string;
  is_taxed: boolean;
  tax_amount: number;
  tax_rate: number;
  object: string;
  subscription_id: string;
  customer_id: string;
  description: string;
  entity_type: string;
  entity_id: string;
  metered: boolean;
  discount_amount: number;
  item_level_discount_amount: number;
};

type LinkedPayment = {
  txn_id: string;
  applied_amount: number;
  applied_at: number;
  txn_status: string;
  txn_date: number;
  txn_amount: number;
};

type Tax = {
  object: string;
  name: string;
  description: string;
  amount: number;
};

export type BillingDetail = {
  billing_plan: string;
  subscription_id: string;
  subscription: Subscription;
  customer: Customer;
  card: Card;
};

export type BillingPlan = {
  plan: 'FREE' | 'GROWTH' | 'PREMIUM';
  title: string;
  price: string;
  features: string[];
  info?: string;
};

export type BillingMatrix = {
  id: string;
  used_states: number;
  used_tax_questions: number;
};

export type OrganizationBillingDetails = {
  billing_plan: BillingPlanEnum;
  subscription_status: string;
};

export enum BillingFrequencyEnum {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}
export type BillingAnalytics = {
  frequency: BillingFrequencyEnum;
  labels: string[];
  values: number[];
};
