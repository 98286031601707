import { Box, Flex, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useGetOrgDetailsListQuery } from 'apis/organizations-apis';
import { ArrowRight, CheckIcon } from 'components/icons';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';

import BusinessContactEdit from './org-details/business-contact-edit';
import BusinessDetailsEdit from './org-details/business-details-edit';
import BusinessInformationEdit from './org-details/business-information-edit';
import BusinessOwnerEdit from './org-details/business-owner-edit';

const SETUP_ORG_DETAILS = {
  BUSINESS_DETAILS: 'Business Details',
  BUSINESS_INFO: 'Business Information',
  CONTACT_PERSON: 'Business Contact Person',
  COMPANY_OWNERS: 'Company Owners',
} as const;

type SetupOrgDetails = (typeof SETUP_ORG_DETAILS)[keyof typeof SETUP_ORG_DETAILS];

/**
 * OrgSetup is the setup page for the organization details.
 * It is used to create a new organization details or to view/edit an existing one.
 */
const OrgSetup = ({ onSetupDone }: { onSetupDone: () => void }) => {
  const { orgId } = useOrg();
  const [activeComponent, setActiveComponent] = useState<SetupOrgDetails>(SETUP_ORG_DETAILS.BUSINESS_DETAILS);

  const buttonLabels = Object.values(SETUP_ORG_DETAILS);

  const { data, isPending: isOrgDetailsFetching } = useGetOrgDetailsListQuery(orgId);

  useEffect(() => {
    if (data) {
      // Find the first incomplete section
      const firstIncompleteSection = buttonLabels.find(label => !hasCompletedData(label));
      if (firstIncompleteSection) {
        setActiveComponent(firstIncompleteSection);
      }
    }
  }, [data]);

  const hasCompletedData = (label: SetupOrgDetails) => {
    const completionCriteria = {
      [SETUP_ORG_DETAILS.BUSINESS_DETAILS]: () => Boolean(data?.business_name) && Boolean(data?.entity_type),
      [SETUP_ORG_DETAILS.BUSINESS_INFO]: () => Boolean(data?.business_phone) && Boolean(data?.business_postal_code),
      [SETUP_ORG_DETAILS.CONTACT_PERSON]: () =>
        Boolean(data?.business_contact_name) && Boolean(data?.business_contact_postal_code),
      [SETUP_ORG_DETAILS.COMPANY_OWNERS]: () =>
        Boolean(data?.business_owner1_name) && Boolean(data?.business_owner1_postal_code),
    };

    return completionCriteria[label]?.() ?? false;
  };

  // Function to render the active component
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case SETUP_ORG_DETAILS.BUSINESS_DETAILS:
        return (
          <BusinessDetailsEdit
            orgDetails={data}
            goNext={() => setActiveComponent(SETUP_ORG_DETAILS.BUSINESS_INFO)}
            isOrgDetailsLoading={isOrgDetailsFetching}
          />
        );
      case SETUP_ORG_DETAILS.BUSINESS_INFO:
        return (
          <BusinessInformationEdit
            orgDetails={data}
            goNext={() => setActiveComponent(SETUP_ORG_DETAILS.CONTACT_PERSON)}
            isOrgDetailsLoading={isOrgDetailsFetching}
          />
        );
      case SETUP_ORG_DETAILS.CONTACT_PERSON:
        return (
          <BusinessContactEdit
            orgDetails={data}
            goNext={() => setActiveComponent(SETUP_ORG_DETAILS.COMPANY_OWNERS)}
            isOrgDetailsLoading={isOrgDetailsFetching}
          />
        );
      case SETUP_ORG_DETAILS.COMPANY_OWNERS:
        return (
          <BusinessOwnerEdit
            orgDetails={data}
            goNext={() => onSetupDone()}
            isOrgDetailsLoading={isOrgDetailsFetching}
          />
        );
      default:
        return null;
    }
  };

  if (isOrgDetailsFetching) {
    return <Skeleton height="100%" width="60.75rem" />;
  }

  return (
    <Flex direction={{ base: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }} columnGap={6}>
      <Box border="1px solid #CFD0D8" borderRadius={4} height="192px">
        <Flex flexDirection="column" justifyContent={'flex-start'} alignItems="flex-start" width="294px" p={4}>
          <VStack justifyContent="flex-start" alignItems="flex-start" w="full">
            {buttonLabels.map(label => (
              <Flex
                key={label}
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                _hover={{
                  bg: '#EFEFF3',
                }}
                bg={activeComponent === label ? '#EFEFF3' : 'transparent'}
                cursor="pointer"
                onClick={() => setActiveComponent(label)}
              >
                <Text py={2} pr={4} pl={2} fontSize="14px">
                  {label}
                </Text>
                <Flex alignItems="center" gap={1}>
                  {hasCompletedData(label) && <CheckIcon />}
                  <ArrowRight />
                </Flex>
              </Flex>
            ))}
          </VStack>
        </Flex>
      </Box>
      <Box border="1px solid #ddd" borderRadius={4} width="100%" p={6}>
        {renderActiveComponent()}
      </Box>
    </Flex>
  );
};

export default OrgSetup;
