import { Button, useDisclosure } from '@chakra-ui/react';

import { ArchiveOrganizationForm } from './archive-org-form';

export const ArchiveOrganization = () => {
  const { open, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="outline" colorPalette="red" onClick={onOpen} w={'120px'}>
        Archive
      </Button>
      {open && <ArchiveOrganizationForm isOpen={open} onClose={onClose} />}
    </>
  );
};
