import { BankDetailsInstance } from 'types/shared-types';

import api from './api';

const addOrUpdateBankDetails = (orgId: string, payload: Partial<BankDetailsInstance>) => {
  return api.put(`/v1/bank_details/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getBankDetails = (orgId: string) => {
  return api.get<BankDetailsInstance>(`/v1/bank_details/`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const BANK_DETAILS_STATE_KEY = 'bankDetails';

export { addOrUpdateBankDetails, BANK_DETAILS_STATE_KEY, getBankDetails };
