import { Flex, HStack, Text } from '@chakra-ui/react';
import { InfoFilled } from 'components/icons';
import { Tooltip } from 'components/ui/tooltip';

type SettingCardProps = {
  title: string;
  subTitle: string;
  actionComponent: React.ReactNode;
  tooltipText?: string;
  showTooltip?: boolean;
};
export const SettingCard = ({ title, subTitle, actionComponent, showTooltip, tooltipText }: SettingCardProps) => {
  return (
    <Flex
      w={{ sm: '100%', md: '100%', lg: '60%' }}
      border="1px solid #ddd"
      p={4}
      borderRadius={4}
      justify={'space-between'}
      gap={8}
    >
      <Flex flexDirection={'column'}>
        <HStack gap={1}>
          <Text fontSize="md" fontWeight="medium">
            {title}
          </Text>

          {showTooltip && (
            <Tooltip positioning={{ placement: 'bottom-start' }} content={tooltipText}>
              <InfoFilled width={18} height={18} />
            </Tooltip>
          )}
        </HStack>

        <Text fontSize="sm" mt={2} color="#4B5169">
          {subTitle}
        </Text>
      </Flex>
      <Flex align={'center'}>{actionComponent}</Flex>
    </Flex>
  );
};
