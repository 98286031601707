import { Text } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type APIKeyDeleteConfirmationModalProps = {
  deleteAPIKey: (apiKeyId: string) => void;
  isDeleteApiKeyIsPending: boolean;
};
export const APIKeyDeleteConfirmationModal = ({
  deleteAPIKey,
  isDeleteApiKeyIsPending,
}: APIKeyDeleteConfirmationModalProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.get('deleteApiKey') === 'true';
  const apiKeyId = searchParams.get('apiKeyId') || '';

  const onClose = useCallback(() => {
    setSearchParams(params => {
      if (params.has('deleteApiKey')) {
        params.delete('deleteApiKey');
      }
      if (params.has('apiKeyId')) {
        params.delete('apiKeyId');
      }

      return params;
    });
  }, []);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>
          <DialogTitle>Confirm API Key Deletion</DialogTitle>
        </DialogHeader>
        <DialogBody pb={6}>
          <Text mb="4">Are you sure you want to delete this API key? This action cannot be undone.</Text>
          <Text fontWeight="medium" mb={1}>
            API Key
          </Text>
          <Text>{apiKeyId}...</Text>
        </DialogBody>
        <DialogFooter>
          <Button disabled={isDeleteApiKeyIsPending} variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isDeleteApiKeyIsPending}
            loading={isDeleteApiKeyIsPending}
            variant="danger"
            colorScheme="red"
            w="6.25rem"
            onClick={async () => {
              await deleteAPIKey(apiKeyId);
              onClose();
            }}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
