import { useACL } from 'app/acl/acl';
import { Button } from 'components/ui/button';
import { Tooltip } from 'components/ui/tooltip';
import { useOrg } from 'hooks/useOrg';
import { useSearchParams } from 'react-router-dom';

export const CreateOrg = () => {
  const { orgId } = useOrg();

  const [, setSearchParams] = useSearchParams();
  const { isAtLeastRole } = useACL();
  const isOwner = isAtLeastRole('Owner');

  return (
    <Tooltip
      content={`You don't have permission to create a test organization. Only the organization owner can do so.`}
      disabled={isOwner}
    >
      <Button
        disabled={!isOwner}
        w={'120px'}
        onClick={() =>
          setSearchParams(params => {
            const newParams = new URLSearchParams(params);
            newParams.set('createOrg', orgId);
            return newParams.toString();
          })
        }
      >
        Create
      </Button>
    </Tooltip>
  );
};
